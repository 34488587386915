import { action } from 'mobx'

import { CustomFilterDialogModes } from '~/client/src/shared/enums/CustomFilterDialogModes'

import ActivityFiltersPageStore from '../ActivityFiltersPage.store'

export default class CustomFiltersSelectorStore {
  public constructor(
    private readonly filtersPageStore: ActivityFiltersPageStore,
  ) {}

  public selectCustomFilter(id: string) {
    this.filtersPageStore.selectedCustomFilterId = id
    const { filtersByFilterType: activityFiltersByFilterType } =
      this.filtersPageStore.selectedCustomFilter

    this.filtersPageStore.codeFilterValuesByTypeMap.clear()
    Object.keys(activityFiltersByFilterType).forEach(filterType => {
      this.filtersPageStore.codeFilterValuesByTypeMap.set(
        filterType,
        activityFiltersByFilterType[filterType],
      )
    })
    this.filtersPageStore.resetActivityPreset()
  }

  @action.bound
  public editCustomFilter() {
    const store = this.filtersPageStore
    if (!store.selectedCustomFilter) {
      return
    }

    store.saveFilterDialogInstance = store.selectedCustomFilter
    store.editableFilterName = store.selectedCustomFilter.name
    store.filterDialogMode = CustomFilterDialogModes.Edit
    store.isSaveFilterDialogShown = true
  }
}
