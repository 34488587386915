import * as React from 'react'

import { classList } from 'react-classlist-helper'

import './SidebarBadge.scss'

export function Badge(props: { content: React.ReactText }) {
  return (
    <div
      className={classList({
        'sidebar-badge text white center': true,
      })}
    >
      {props.content}
    </div>
  )
}
