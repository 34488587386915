import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { QRCode } from 'react-qrcode-logo'
import { RouteComponentProps } from 'react-router'

import { baseUrl } from '~/client/src/shared/Config'
import { Loader } from '~/client/src/shared/components/Loader'
import PageActionHeader from '~/client/src/shared/components/PageActionHeader/PageActionHeader'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { ScanCodeTypes } from '~/client/src/shared/models/Scanner'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'
import { QR_CODE_LOGO_SRC } from '~/client/src/shared/utils/usefulStrings'

import MobileView from '../../components/MobileView'
import MobileInitialState from '../../stores/MobileInitialState'
import MobileCommonStore from '../../stores/ui/MobileCommon.store'

import Colors from '~/client/src/shared/theme.module.scss'

import './AddTeammates.scss'

interface IRouteProps {
  userId?: string
}

interface IProps {
  state?: MobileInitialState
  common?: MobileCommonStore
}

const mutationEvents = []

const useTheQRCode =
  'Use the QR code below to invite your teammates to the project.'

const QR_CODE_TO_PRINT_SIZE = 240
const backIconSize = 20

@inject('state', 'common')
@observer
export default class AddTeammatesView extends React.Component<
  IProps & RouteComponentProps<IRouteProps>
> {
  public constructor(props: IProps & RouteComponentProps<IRouteProps>) {
    super(props)
  }

  public render() {
    return <MobileView content={this.content} header={this.header} />
  }

  private get shouldRenderLoader(): boolean {
    const { isLoading, loading } = this.props.state

    return isLoading && !mutationEvents.some(e => loading.get(e))
  }

  private get header(): JSX.Element {
    return (
      <PageActionHeader
        title={Localization.translator.addTeammates}
        customLeftComponent={
          <Icon
            iconSize={backIconSize}
            className="text large no-grow"
            icon={IconNames.CROSS}
            onClick={this.props.common.history.goBack}
          />
        }
      />
    )
  }

  private get content(): JSX.Element {
    if (this.shouldRenderLoader) {
      return <Loader />
    }

    return (
      <div className="add-teammates-container">
        <div className="text header pa15 mt10">{useTheQRCode}</div>
        <div className="qr-code-container col x-center">
          <QRCode
            value={this.projectQRCodeValue}
            logoImage={QR_CODE_LOGO_SRC}
            size={QR_CODE_TO_PRINT_SIZE}
            fgColor={ThemeMode.getHEXColor(Colors.neutral0)}
          />
          <div className="row x-center text extra-large">
            {this.props.state.activeProject.name}
          </div>
        </div>
      </div>
    )
  }

  private get projectQRCodeValue(): string {
    return `${baseUrl}/${ScanCodeTypes.project}/${this.props.state.activeProject.code}/home`
  }
}
