import React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import CalendarDayView, {
  CalendarDayMode,
} from '~/client/src/shared/components/CalendarDayView/CalendarDayView'
import CalendarDayViewStore from '~/client/src/shared/components/CalendarDayView/CalendarDayView.store'
import MultiDayEventsBar from '~/client/src/shared/components/CalendarDayView/components/MultiDayEventsBar/MultiDayEventsBar'
import WeatherForecastBar from '~/client/src/shared/components/WeatherForecastBar/WeatherForecastBar'
import CalendarEvent, {
  CalendarEventEntityType,
  CalendarEventLabelType,
} from '~/client/src/shared/models/CalendarEvent'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import EventStyleCssAdapter from '~/client/src/shared/utils/EventStyleCssAdapter'

import MobileLogisticsStore from '../../stores/MobileLogistics.store'

import Colors from '~/client/src/shared/theme.module.scss'

export interface IProps {
  logisticsStore: MobileLogisticsStore
  onNewEventCreate: () => void
  onEventClicked: (event: CalendarEvent) => void

  withMultiDayEventsBar?: boolean

  eventsStore?: EventsStore
  projectDateStore?: ProjectDateStore
  companiesStore?: CompaniesStore
  permitTypesStore?: PermitTypesStore
  locationAttributesStore?: LocationAttributesStore
}

@inject(
  'eventsStore',
  'projectDateStore',
  'companiesStore',
  'permitTypesStore',
  'locationAttributesStore',
)
@observer
export default class MobileLogisticsCalendar extends React.Component<IProps> {
  private store: CalendarDayViewStore

  public constructor(props: IProps) {
    super(props)

    this.store = new CalendarDayViewStore(
      props.projectDateStore,
      props.companiesStore,
      CalendarEventEntityType.Form,
      null,
      props.withMultiDayEventsBar,
    )
  }

  public render() {
    const {
      eventsStore,
      logisticsStore: {
        viewingDate,
        setViewingDate,
        prevDate,
        nextDate,
        startDate,
        isCreationAvailable,
      },
    } = this.props

    const mode = eventsStore.appState.isPresentationMode
      ? CalendarDayMode.VIEW
      : CalendarDayMode.CREATE

    return (
      <div className="col full-height">
        <div className="body-header full-width bb-light-grey sticky z-index-10">
          <WeatherForecastBar
            className="row nowrap bg-palette-brand-lightest"
            date={startDate}
          />
          {this.props.withMultiDayEventsBar && (
            <MultiDayEventsBar
              events={this.events}
              viewingDate={viewingDate}
              setViewingDate={setViewingDate}
              calendarStore={this.store}
            />
          )}
        </div>

        <CalendarDayView
          events={this.events}
          store={this.store}
          activeDate={viewingDate}
          mode={mode}
          onNewEventCreate={this.props.onNewEventCreate}
          onEventClicked={this.props.onEventClicked}
          onLeftSwipe={nextDate}
          onRightSwipe={prevDate}
          shouldHandleMouse={false}
          isEventCreationDisabled={!isCreationAvailable}
        />
      </div>
    )
  }

  @computed
  private get events(): CalendarEvent[] {
    const { logisticsStore, locationAttributesStore } = this.props

    return logisticsStore.sitePermitsForCalendar.map(permit => {
      const location = locationAttributesStore.getLocationBySiteLocationObj(
        permit.locations[0],
      )

      const eventStyle = permit.getEventStyleByColor(
        location?.color || Colors.primary20,
      )
      const cssAdapter = new EventStyleCssAdapter(eventStyle)

      return CalendarEvent.createEvent({
        startDate: new Date(permit.startDate),
        endDate: new Date(permit.endDate),
        dataId: permit.id,
        projectDateStore: this.props.projectDateStore,
        entityType: CalendarEventEntityType.Form,
        labelType: CalendarEventLabelType.Default,
        styles: cssAdapter.getStyles(),
        data: permit,
        label: permit.getCaption(this.props.permitTypesStore),
      })
    })
  }
}
