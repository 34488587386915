import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'
import PermitTypeSelector from '~/client/src/shared/components/PermitTypeSelector/PermitTypeSelector'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import MobileView from '../../components/MobileView'
import MobileCommonStore from '../../stores/ui/MobileCommon.store'

import './PermitTypesView.scss'

interface IProps {
  common?: MobileCommonStore
}

// translated

@inject('common')
@observer
export default class PermitTypesView extends React.Component<IProps> {
  public componentDidMount() {
    this.props.common.hideNavBar()
  }

  public render() {
    return (
      <MobileView
        className="permit-types-container"
        header={this.renderHeader()}
        content={<PermitTypeSelector />}
      />
    )
  }

  private renderHeader = (): JSX.Element => {
    return (
      <div
        className="row header pa10"
        onClick={() => this.props.common.displayHomeView()}
      >
        <Icon icon={IconNames.CHEVRON_LEFT} />
        <div className="row ml12 text large light">
          <Icons.Home className="row mr8 no-grow" />
          {Localization.translator.goToProjectHome}
        </div>
      </div>
    )
  }
}
