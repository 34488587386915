import { FirebaseStorage } from 'firebase/storage'
import { action } from 'mobx'

import { IQuery } from '~/client/graph'
import MobileEventStore from '~/client/src/mobile/stores/EventStore/MobileEvents.store'
import MobileInitialState from '~/client/src/mobile/stores/MobileInitialState'
import MobileActivityListStore from '~/client/src/mobile/stores/ui/ActivityList.store'
import MobileCommonStore from '~/client/src/mobile/stores/ui/MobileCommon.store'
import { IAppConfig } from '~/client/src/shared/Config'
import Guard from '~/client/src/shared/utils/Guard'

import ApiAuthService from '../../shared/services/ApiAuthService'
import FirebaseAuthService from '../../shared/services/FirebaseAuthService'
import ProcoreService from '../../shared/services/ProcoreService'
import RootStore from '../../shared/stores/Root.store'
import GraphExecutor from '../../shared/utils/GraphExecutor'

export default class MobileRootStore extends RootStore {
  public activityListStore: MobileActivityListStore
  public common: MobileCommonStore

  public constructor(
    configuration: IAppConfig,
    public state: MobileInitialState,
    storage: FirebaseStorage,
    public eventsStore: MobileEventStore,
    firebaseAuthService: FirebaseAuthService,
    apiAuthService: ApiAuthService,
    procoreService: ProcoreService,
    graphExecutor: GraphExecutor,
    isTestEnv: boolean,
  ) {
    super(
      state,
      storage,
      configuration,
      eventsStore,
      MobileCommonStore,
      firebaseAuthService,
      apiAuthService,
      procoreService,
      graphExecutor,
      isTestEnv,
    )

    Guard.requireAll({
      state,
      storage,
      firebaseAuthService,
      apiAuthService,
      eventsStore,
    })

    this.activityListStore = new MobileActivityListStore(
      this.activitiesStore,
      this.state,
      this.eventsStore,
      this.activityAssignmentsStore,
      this.activityFollowingsStore,
      this.activityFiltersStore,
      this.activityDetailsStore,
      this.activityPresetsStore,
      this.projectDateStore,
      this.statusUpdatesStore,
      this.rfisStore,
      this.flagsStore,
      this.scheduleCommentsStore,
      this.categoriesOfVarianceStore,
      this.safetyHazardsStore,
      this.deliveriesStore,
      this.customActivityListFiltersStore,
      this.companiesStore,
    )
  }

  @action
  public clearStoresData() {
    this.clearOutScheduleData()

    this.activityCodeLocationRelationshipsStore.clearList()
    this.statusUpdatesStore.clearList()
    this.messagesStore.clearList()
    this.deliveriesStore.clearList()
    this.basemapsStore.clearList()
    this.sitemapsStore.clearList()
    this.sitemapItemsStore.clearList()
    this.projectTeamsStore.clearList()
    this.projectRolesStore.clearList()
    this.operationRulesStore.clearList()
    this.weatherForecastsStore.clearData()
    this.recurringDeliveriesSettingsStore.clearData()
    this.concreteDirectOrdersStore.clearData()
    this.concreteDirectPayloadsStore.clearData()
    this.materialsStore.clearList()
    this.materialCategoryStore.clearList()
  }

  @action
  public setProjectDataFromSchedule(schedule: IQuery) {
    super.setProjectDataFromSchedule(schedule)
  }
}
