import * as React from 'react'

import { RouteComponentProps, withRouter } from 'react-router'

import LoginView from '~/client/src/shared/components/Login/LoginView'

// should stay as wrapper according to [pr #577]
class MobileLoginView extends React.Component<RouteComponentProps<any>> {
  public render() {
    return <LoginView />
  }
}

export default withRouter(MobileLoginView)
