import * as React from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import MobileView from '~/client/src/mobile/components/MobileView'
import MobileEventStore from '~/client/src/mobile/stores/EventStore/MobileEvents.store'
import MobileCommonStore from '~/client/src/mobile/stores/ui/MobileCommon.store'
import { Loader } from '~/client/src/shared/components/Loader'
import { UrlParamKey } from '~/client/src/shared/constants/commonRoutes'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { SEND_SMS_DELIVERY_TICKET } from '~/client/src/shared/stores/EventStore/eventConstants'

// translated

interface IProps {
  common?: MobileCommonStore
  eventsStore?: MobileEventStore
}

@inject('common', 'eventsStore')
@observer
export default class SendDeliveryTicketView extends React.Component<IProps> {
  @observable private error: string

  public componentDidMount() {
    this.props.common.hideNavBar()
    const urlParams = new URLSearchParams(window.location.search)
    const deliveryId = urlParams.get(UrlParamKey.DeliveryId)

    if (deliveryId) {
      this.props.eventsStore.dispatch(
        SEND_SMS_DELIVERY_TICKET,
        deliveryId,
        this.setErrorMessage,
      )
    } else {
      this.error = Localization.translator.deliveryIdNotProvided
    }
  }

  public render() {
    return <MobileView content={this.content} />
  }

  public get content() {
    if (this.error) {
      return this.renderWarning(this.error)
    }

    if (this.props.eventsStore.appState.loading.get(SEND_SMS_DELIVERY_TICKET)) {
      return this.renderLoader()
    }

    return this.renderSuccessMessage()
  }

  private renderLoader() {
    return (
      <div className="ma-auto">
        <Loader className="row" size={25} />
        {Localization.translator.sendingDeliveryTicket}
      </div>
    )
  }

  private renderWarning(error: string) {
    return <div className="ma-auto">{error}</div>
  }

  private renderSuccessMessage() {
    return (
      <div className="ma-auto">
        {Localization.translator.ticketSuccessfullySent}
      </div>
    )
  }

  @action.bound
  private setErrorMessage(error: any) {
    this.error = error?.message || Localization.translator.failedToSendTicket
  }
}
