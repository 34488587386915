import { action, observable } from 'mobx'

import { IDeliveryTicketResponse } from '~/client/graph'
import MobileEventStore from '~/client/src/mobile/stores/EventStore/MobileEvents.store'
import { GET_DELIVERY_TICKET } from '~/client/src/shared/stores/EventStore/eventConstants'
import { isEmptyObj } from '~/client/src/shared/utils/util'

export default class DeliveryTicketStore {
  @observable public isTicketCorrupted: boolean = false
  @observable private deliveryTicket: IDeliveryTicketResponse = null

  public constructor(
    private readonly eventsStore: MobileEventStore,
    private readonly deliveryId: string = null,
  ) {}

  public get ticket(): IDeliveryTicketResponse {
    return this.deliveryTicket
  }

  public set ticket(ticket: IDeliveryTicketResponse) {
    if (ticket && !isEmptyObj(ticket)) {
      this.deliveryTicket = ticket
    } else {
      this.markAsCorrupted()
    }
  }

  @action.bound
  public requestDeliveryTicket() {
    this.eventsStore.dispatch(GET_DELIVERY_TICKET, {
      deliveryId: this.deliveryId,
      postActionSuccess: this.setDeliveryTicket,
      postActionError: this.markAsCorrupted,
    })
  }

  public get mapsUrls(): string[] {
    return this.ticket.sitemapUrls || []
  }

  private markAsCorrupted = () => {
    this.isTicketCorrupted = true
  }

  private setDeliveryTicket = (ticket: IDeliveryTicketResponse) => {
    this.ticket = ticket
  }
}
