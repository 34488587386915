import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import MobileEventStore from '~/client/src/mobile/stores/EventStore/MobileEvents.store'
import MobileActivityListStore from '~/client/src/mobile/stores/ui/ActivityList.store'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import { GET_SCHEDULE_SUCCESS } from '~/client/src/shared/stores/EventStore/eventConstants'

import ActivityList from './ActivityList'
import ActivityFiltersPage from './components/ActvityFiltersPage/ActivityFiltersPage'

interface IProps {
  activityListStore?: MobileActivityListStore
  eventsStore?: MobileEventStore
}

@inject('eventsStore', 'activityListStore')
@observer
export default class ActivityListView extends React.Component<IProps> {
  private clearPostEventCallback: () => void

  public UNSAFE_componentWillMount() {
    const { eventsStore, activityListStore } = this.props
    activityListStore.setInitialFilterValues()
    this.clearPostEventCallback = eventsStore.addPostEventCallback(
      this.setInitialFilterValues,
    )
  }

  public componentWillUnmount() {
    if (this.clearPostEventCallback) {
      this.clearPostEventCallback()
    }
  }

  public render() {
    const { isFilterPageShown } = this.props.eventsStore.appState.filters

    return isFilterPageShown ? <ActivityFiltersPage /> : <ActivityList />
  }

  @action.bound
  private setInitialFilterValues(eventContext: EventContext) {
    if (eventContext.event[0] === GET_SCHEDULE_SUCCESS) {
      this.props.activityListStore.setInitialFilterValues()
      this.clearPostEventCallback()
    }
  }
}
