import commonRoutes, {
  bareRoutes,
  isPathToDetails,
  projectBased,
} from '~/client/src/shared/constants/commonRoutes'

export type MobileRoute = keyof typeof mobileRoutes

const mobileRoutes = {
  ...commonRoutes,

  PHOTO_DETAIL: projectBased('/photo/:photoId'),
  FLAG: projectBased('/flags/:flagId'),
  RFI: projectBased('/rfi/:rfiId'),
  SCHEDULE_COMMENT: projectBased('/schedule-comment/:scId'),
  CATEGORY_OF_VARIANCE: projectBased('/category-of-variance/:covId'),
  SAFETY_HAZARD: projectBased('/safety-hazard/:shId'),
  STATUS_UPDATE_MESSAGES: projectBased(
    '/status-update-messages/:statusUpdateId',
  ),
  FORM_VIEW: projectBased(`${bareRoutes.FORMS}/:formId`),
  ANNOUNCEMENT_DETAILS: projectBased('/announcement/:announcementId'),

  DOCUMENTS: '/documents',
  SEND_SMS_DELIVERY_TICKET: '/send-sms-ticket',
  BULK_STATUS_UPDATE: projectBased(
    bareRoutes.BULK_STATUS_UPDATE + '/:activityCode/:company?',
  ),
  USERS_DIRECTORY: projectBased('/users'),
  QR_CODES: projectBased(bareRoutes.QR_CODES),
  NOTIFICATION_SETTINGS: projectBased(bareRoutes.NOTIFICATION_SETTINGS),
  QR_CODE: projectBased('/qr-code'),
  QR_CODE_ACCESS: projectBased('/qr-code-access/:userId'),
  USER_PROFILE: '/users/:userId',
  CHAT: '/chat',
  ADD_TEAMMATE: projectBased('/add-teammates'),

  ONE_TIME_POPUP: '/one-time-popup',
}

export function isMobileRoute(route): route is MobileRoute {
  return !!mobileRoutes[route]
}

// TODO: Examine it, do we really need it?
export function shouldRedirect(currentPath: string, redirectTo: string) {
  const {
    LOGIN,
    SAVE_PASSWORD,
    PROJECTS,
    ACTIVITIES,
    HOME,
    DELIVERIES,
    SEND_SMS_DELIVERY_TICKET,
    BULK_STATUS_UPDATE,
    USERS_DIRECTORY,
    FORM_TYPES,
    ADD_TEAMMATE,
  } = mobileRoutes

  switch (redirectTo) {
    case PROJECTS:
      return ![LOGIN, bareRoutes.VISITOR, SAVE_PASSWORD, PROJECTS].some(r =>
        r.includes(currentPath),
      )

    case ACTIVITIES:
    case USERS_DIRECTORY:
    case DELIVERIES:
    case HOME:
      return (
        !isPathToDetails(currentPath) &&
        ![
          FORM_TYPES,
          LOGIN,
          bareRoutes.VISITOR,
          SAVE_PASSWORD,
          PROJECTS,
          ADD_TEAMMATE,
          bareRoutes.ACTIVITIES,
          bareRoutes.USERS_DIRECTORY,
          bareRoutes.DELIVERIES,
          bareRoutes.FORMS,
          bareRoutes.NOTIFICATIONS,
          bareRoutes.DOCUMENTS,
          bareRoutes.QR_CODES,
          bareRoutes.NOTIFICATION_SETTINGS,
          SEND_SMS_DELIVERY_TICKET,
          BULK_STATUS_UPDATE,
          bareRoutes.HOME,
        ].some(route => currentPath.includes(route))
      )
    case commonRoutes.NOTIFICATIONS:
      return true

    default:
      return false
  }
}

export default mobileRoutes
