import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { action } from 'mobx'
import { observer } from 'mobx-react'

import './NoActivitiesBanner.scss'

interface IProps {
  isFilterApplied: boolean
  onClickClearFilter?: () => any
}

@observer
export default class NoActivitiesBanner extends React.Component<IProps> {
  public render() {
    const { isFilterApplied } = this.props

    if (!isFilterApplied) {
      return (
        <div className="no-activity-message row x-center pt10 bt-light-grey">
          You have no assigned activities for today
        </div>
      )
    }

    return (
      <>
        <div className="col x-center y-center pt10 bt-light-grey">
          <div className="row text large">
            <Icon
              icon="warning-sign"
              iconSize={20}
              className="warning-icon mr5"
            />
            No Activities Passing Filters
          </div>
        </div>
        <div className="row">
          <button
            className="btn-clear-filter ba-none full-width brada2 text extra-large bold-font no-outline m20"
            onClick={this.clickClearFilter}
          >
            Clear Filters to See Activities
          </button>
        </div>
      </>
    )
  }

  @action.bound
  private clickClearFilter() {
    if (this.props.onClickClearFilter) {
      this.props.onClickClearFilter()
    }
  }
}
