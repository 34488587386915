import * as React from 'react'

import { inject, observer } from 'mobx-react'

import MobileView from '~/client/src/mobile/components/MobileView'
import MobileCommonStore from '~/client/src/mobile/stores/ui/MobileCommon.store'
import EmailLinkerForm from '~/client/src/shared/components/EmailLinkerForm/EmailLinkerForm'
import LanguageSelector from '~/client/src/shared/components/LanguageSelector/LanguageSelector'
import SavePassword from '~/client/src/shared/components/SavePassword/SavePassword'
import { LocalStorageKey } from '~/client/src/shared/enums/LocalStorageKey'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { SAVE_PASSWORD_SUCCESS } from '~/client/src/shared/stores/EventStore/eventConstants'
import AuthenticationStore from '~/client/src/shared/stores/domain/Authentication.store'
import LocalStorageHelper from '~/client/src/shared/utils/LocalStorageHelper'

import MobileEventStore from '../../stores/EventStore/MobileEvents.store'

import './SavePasswordView.scss'

interface IProps {
  auth?: AuthenticationStore
  common?: MobileCommonStore
  eventsStore?: MobileEventStore
}

@inject('auth', 'common', 'eventsStore')
@observer
class SavePasswordView extends React.Component<IProps> {
  public get content() {
    const body = this.props.auth.hasAuthUserLinkedEmail ? (
      <SavePassword />
    ) : (
      <div className="col no-grow">
        <h1 className="ma0 text header pb24">
          {Localization.translator.userEmailLinking.linkYourEmailAndSetPassword}
        </h1>
        <EmailLinkerForm
          onSkip={this.handleEmailLinkingSkip}
          onComplete={this.handleEmailLinkingComplete}
        />
      </div>
    )

    return (
      <div className="save-password mobile-save-password">
        <div className="save-password-popup">
          {body}
          {Localization.shouldShowLanguageSelector && (
            <LanguageSelector className="extra-large mt20 primary-blue" />
          )}
        </div>
      </div>
    )
  }

  public render() {
    return <MobileView content={this.content} />
  }

  public componentDidMount() {
    this.props.common.hideNavBar()
  }

  private handleEmailLinkingComplete = () => {
    this.props.eventsStore.dispatch(SAVE_PASSWORD_SUCCESS)
  }

  private handleEmailLinkingSkip = () => {
    const { eventsStore } = this.props
    LocalStorageHelper.setUserPreferences(eventsStore.appState.user.id, {
      [LocalStorageKey.ShouldSkipEmailLinking]: true,
    })

    eventsStore.dispatch(SAVE_PASSWORD_SUCCESS)
  }
}

export default SavePasswordView
