import '../shared/styles.scss'
import '~/client/src/shared/tones.scss'
import 'core-js/es'

import * as React from 'react'

import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import { initializeApp } from 'firebase/app'
import { Provider } from 'mobx-react'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import MobileRoutes from '~/client/src/mobile/mobile.routes'
import config from '~/client/src/shared/Config'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import {
  COLLECT_TELEMETRY,
  LISTEN_TO_DEPLOYMENT_VERSION,
  NO_EFFECT,
  START_ALL,
} from '~/client/src/shared/stores/EventStore/eventConstants'

import Localization from '../shared/localization/LocalizationManager'
import ThemeMode from '../shared/utils/ThemeModeManager'
import Container from './container'

const eventsToHideInLog = [COLLECT_TELEMETRY, NO_EFFECT]

const hot = 'hot'
if (module[hot]) {
  module[hot].accept()
}

function startApp() {
  if (config.ENVIRONMENT.toLowerCase() !== 'development') {
    Sentry.init({
      dsn: 'https://c4509968fb074001bfefc1902752cee6@sentry.io/1819303',
      environment: config.ENVIRONMENT,
      release: `StruxHub-Mobile@${config.VERSION}`,
      attachStacktrace: true,
    })
  }

  Localization.init()

  const app = initializeApp(config.FIREBASE_CONFIG)
  const isTestEnv = ['qa', 'development'].includes(
    config.ENVIRONMENT.toLowerCase(),
  )
  const container = new Container(app, isTestEnv)
  const { rootStore } = container
  const { eventsStore } = rootStore

  ThemeMode.init()

  if (isTestEnv) {
    window.stores = rootStore
    eventsStore.addPostEventCallback(({ event }: EventContext) => {
      const [type] = event
      if (eventsToHideInLog.includes(type)) {
        return
      }

      console.groupCollapsed(type)

      console.groupEnd()
    })
  }

  eventsStore.dispatch(START_ALL, () => {
    render(
      <ApolloProvider client={eventsStore.appState.graphClient}>
        <Provider {...rootStore}>
          <BrowserRouter>
            <MobileRoutes />
          </BrowserRouter>
        </Provider>
      </ApolloProvider>,
      document.getElementById('app'),
    )
  })

  eventsStore.dispatch(LISTEN_TO_DEPLOYMENT_VERSION)
}

config.READY.then(startApp)
