import { computed } from 'mobx'

import { IGroupByOption } from '~/client/src/shared/components/CompactGroupByPicker/CompactGroupByFilter'
import LogisticsGroupingOption, {
  formsGroupingOptionList,
  hubGroupingOptionList,
} from '~/client/src/shared/enums/LogisticsGroupingOption'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import { IFilters } from '~/client/src/shared/stores/InitialState'

export default class MobileLogisticsGroupingOptionsStore {
  public constructor(
    private filters: IFilters,
    private translatorKeyGetter: (id: string) => KnownTranslatorKeys,
    private readonly isPermitOnly: boolean,
  ) {}

  @computed
  public get groupByOptions(): IGroupByOption[] {
    const availableGroupingTypes: LogisticsGroupingOption[] = this.isPermitOnly
      ? formsGroupingOptionList
      : hubGroupingOptionList
    return availableGroupingTypes.map(type => ({
      title: Localization.translator[this.translatorKeyGetter(type)],
      groupByType: type,
      shouldRenderSeparator: true,
    }))
  }
}
