import * as React from 'react'

import { inject, observer } from 'mobx-react'

import NotificationsSettingsControls from '~/client/src/shared/components/NotificationsSettingsControls/NotificationsSettingsControls'

import BottomNavigationNavBar from '../../components/BottomNavigationNavBar/BottomNavigationNavBar'
import MobileView from '../../components/MobileView'
import MobileCommonStore from '../../stores/ui/MobileCommon.store'

interface IProps {
  common?: MobileCommonStore
}

// localization: no display text to translate

@inject('common')
@observer
export default class NotificationsSettingsView extends React.Component<IProps> {
  public render() {
    return (
      <MobileView
        content={<NotificationsSettingsControls className="col pa10" />}
        footer={<BottomNavigationNavBar />}
      />
    )
  }
}
