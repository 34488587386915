import { action } from 'mobx'
import { inject, observer } from 'mobx-react'

import MobileCommonStore from '~/client/src/mobile/stores/ui/MobileCommon.store'
import FileInputBase from '~/client/src/shared/components/FileInput/FileInput'
import FileType from '~/client/src/shared/enums/FileType'
import { SHOW_FULLSCREEN_PREVIEW } from '~/client/src/shared/stores/EventStore/eventConstants'
import { FileUploadingStore } from '~/client/src/shared/stores/domain/FileUploading.store'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'

import MobileEventStore from '../../stores/EventStore/MobileEvents.store'

import '~/client/src/shared/components/FileInput/FileInput.scss'

interface IFileInputProps {
  id: string
  name: string
  value: string
  onChange?: (newUrlValue, newName) => void
  isReadonly: boolean
  textClassName: string
  fileUploadingStore?: FileUploadingStore
  isFlexPreview?: boolean
  common?: MobileCommonStore
  shouldUseFullSize?: boolean
  shouldHideIconAndOutline?: boolean
  slackBarStore?: SlackBarStore
  eventsStore?: MobileEventStore
  shouldHideName?: boolean
  className?: string
}

@inject('fileUploadingStore', 'common', 'eventsStore', 'slackBarStore')
@observer
export default class MobileFileInput extends FileInputBase<IFileInputProps> {
  public constructor(props: IFileInputProps) {
    super(props)
  }

  @action.bound
  protected showFullscreenPreview(event) {
    this.stopPropagation(event)
    const { value, name, eventsStore } = this.props

    if (value) {
      const file = { fileUrl: value, fileType: FileType.Image, fileName: name }
      eventsStore.dispatch(SHOW_FULLSCREEN_PREVIEW, [file], 0)
    }
  }
}
