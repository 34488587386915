import * as React from 'react'

interface ITextProps {
  children: any
  textAlign?: 'left' | 'right' | 'center'
  textStyle?: Partial<React.CSSProperties>
}

export default function Text({
  children,
  textAlign = 'left',
  textStyle = {},
}: ITextProps) {
  return (
    <div
      style={{
        textAlign,
        ...textStyle,
      }}
    >
      {children}
    </div>
  )
}
