import { action, computed, observable } from 'mobx'

import Activity from '~/client/src/shared/models/Activity'
import Announcement from '~/client/src/shared/models/Announcement'
import { ICalendarDates } from '~/client/src/shared/models/CalendarEvent'
import Delivery from '~/client/src/shared/models/Delivery'
import ILogisticItem, {
  LogisticItemApp,
} from '~/client/src/shared/models/ILogisticItem'
import SitePermit from '~/client/src/shared/models/Permit'
import { IFilters } from '~/client/src/shared/stores/InitialState'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityFollowingsStore from '~/client/src/shared/stores/domain/ActivityFollowings.store'
import AnnouncementFollowingsStore from '~/client/src/shared/stores/domain/AnnouncementFollowings.store'
import AnnouncementsStore from '~/client/src/shared/stores/domain/Announcements.store'
import CastFollowingsStore from '~/client/src/shared/stores/domain/CastFollowings.store'
import ClosureFollowingsStore from '~/client/src/shared/stores/domain/ClosureFollowings.store'
import ClosuresStore from '~/client/src/shared/stores/domain/Closures.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import DeliveryFollowingsStore from '~/client/src/shared/stores/domain/DeliveryFollowings.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import MaturixCastsStore from '~/client/src/shared/stores/domain/MaturixStores/MaturixCasts.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import SitePermitFollowingsStore from '~/client/src/shared/stores/domain/SitePermitFollowings.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import BaseLogisticsStore from '~/client/src/shared/stores/ui/BaseLogistics.store'
import ProjectDateStore, {
  areIntervalTimesIntersects,
} from '~/client/src/shared/stores/ui/ProjectDate.store'

import MobileEventStore from '../../../stores/EventStore/MobileEvents.store'
import MobileInitialState from '../../../stores/MobileInitialState'

export enum LogisticsPageViewMode {
  Map = 'map',
  List = 'list',
  Calendar = 'calendar',
}

export interface IMobileLogisticsInstance {
  app: LogisticItemApp
  permit?: SitePermit
  delivery?: Delivery
  announcement?: Announcement
  activity?: Activity
}

export default class MobileLogisticsStore extends BaseLogisticsStore {
  @observable public selectedViewMode: LogisticsPageViewMode =
    LogisticsPageViewMode.List
  @observable public viewingDate: Date = new Date()

  @observable private placeholderStartDate: Date
  @observable private placeholderEndDate: Date

  public constructor(
    protected readonly announcementsStore: AnnouncementsStore,
    protected readonly sitePermitsStore: SitePermitsStore,
    protected readonly deliveriesStore: DeliveriesStore,
    protected readonly activitiesStore: ActivitiesStore,
    protected readonly projectDateStore: ProjectDateStore,
    protected readonly statusUpdatesStore: StatusUpdatesStore,
    protected readonly eventsStore: MobileEventStore,
    protected readonly sitemapsStore: SitemapsStore,
    protected readonly deliveryFollowingsStore: DeliveryFollowingsStore,
    protected readonly sitePermitFollowingsStore: SitePermitFollowingsStore,
    protected readonly activityFollowingsStore: ActivityFollowingsStore,
    protected readonly announcementFollowingsStore: AnnouncementFollowingsStore,
    maturixCastsStore: MaturixCastsStore,
    closureFollowingsStore: ClosureFollowingsStore,
    castFollowingsStore: CastFollowingsStore,
    closuresStore: ClosuresStore,
    permitTypesStore: PermitTypesStore,
    locationAttributesStore: LocationAttributesStore,
    companiesStore: CompaniesStore,
    protected readonly isPermitOnly?: boolean,
  ) {
    super(
      announcementsStore,
      sitePermitsStore,
      projectDateStore,
      deliveriesStore,
      activitiesStore,
      statusUpdatesStore,
      eventsStore,
      sitemapsStore,
      maturixCastsStore,
      deliveryFollowingsStore,
      sitePermitFollowingsStore,
      activityFollowingsStore,
      closureFollowingsStore,
      announcementFollowingsStore,
      castFollowingsStore,
      permitTypesStore,
      locationAttributesStore,
      companiesStore,
      closuresStore,
      isPermitOnly,
    )
  }

  public convertEntitiesToLogistics = (
    forms: SitePermit[],
    closures,
    sensors,
    announcements: Announcement[] = [],
    deliveries: Delivery[] = [],
    activities: Activity[] = [],
  ): ILogisticItem[] => {
    const _forms = forms.map<ILogisticItem>(form => ({
      id: form.id,
      entityId: form.id,
      app: LogisticItemApp.FORM,
      type: form.getTypeOfPermitType(this.permitTypesStore),
      name: form.getCaption(this.permitTypesStore),
      locations:
        form.locations.reduce((list, loc) => {
          const formLocation = this.getLocationById(loc.id)
          if (formLocation) {
            list.push(formLocation)
          }
          return list
        }, []) || [],
      status: form.status,
      isStatusLate: form.isLate,
      workflowStepLevel: form.workflowStepLevel,
      companyIds: form.companyIds || [],
      startDate: form.startDate,
      endDate: form.endDate,
      responsibleContactIds: form.requesterIds || [],
    }))

    if (this.isPermitOnly) {
      return _forms
    }

    const _announcements = announcements.map<ILogisticItem>(announcement => {
      const location = this.getLocationById(announcement.location?.id)
      return {
        id: announcement.id,
        app: LogisticItemApp.ANNOUNCEMENT,
        name: announcement.getDisplayedName(),
        entityId: announcement.id,
        locations: location ? [location] : [],
        status: null,
        companyIds: [],
        startDate: announcement.startDate,
        endDate: announcement.endDate,
        responsibleContactIds: [],
      }
    })

    const _deliveries = deliveries.map<ILogisticItem>(delivery => ({
      id: delivery.id,
      entityId: delivery.id,
      app: LogisticItemApp.DELIVERY,
      name: delivery.codeToDisplay(this.companiesStore),
      locations: this.getDeliveryLocations(delivery),
      status: delivery.status,
      companyIds: delivery.company ? [delivery.company] : [],
      startDate: delivery.startDate,
      endDate: delivery.endDate,
      responsibleContactIds: delivery.onSiteContactPersonIds,
    }))

    const _activities = activities.map<ILogisticItem>(activity => ({
      id: activity.id,
      app: LogisticItemApp.SCHEDULE,
      name: activity.name,
      entityId: activity.id,
      locations:
        activity.locations
          ?.map(location => this.getLocationById(location.id))
          ?.filter(l => !!l) || [],
      status: activity.status,
      companyIds: activity.assignedCompanyId
        ? [activity.assignedCompanyId]
        : [],
      startDate: activity.plannedStartDate?.getTime(),
      endDate: activity.plannedEndDate?.getTime(),
      responsibleContactIds: activity.requesterId ? [activity.requesterId] : [],
    }))

    return [..._forms, ..._announcements, ..._deliveries, ..._activities]
  }

  @computed
  public get calendarEventDates(): ICalendarDates {
    if (this.placeholderStartDate && this.placeholderEndDate) {
      return {
        startDate: this.placeholderStartDate,
        endDate: this.placeholderEndDate,
      }
    }
    return null
  }

  public get startDate(): Date {
    return this.projectDateStore.startOfDay(this.viewingDate)
  }

  public get endDate(): Date {
    return this.projectDateStore.endOfDay(this.viewingDate)
  }

  public get listFilters(): IFilters {
    return this.isPermitOnly
      ? this.state.formsFilters
      : this.state.logisticsFilters
  }

  public get mapFilters(): IFilters {
    return this.isPermitOnly
      ? this.state.formsMapFilters
      : this.state.logisticsMapFilters
  }

  @action.bound
  public setViewingDate(value: Date) {
    this.viewingDate = value
  }

  @action.bound
  public setViewMode(value: LogisticsPageViewMode) {
    this.selectedViewMode = value
    window.location.hash = value
  }

  protected get isCalendarViewMode(): boolean {
    return this.selectedViewMode === LogisticsPageViewMode.Calendar
  }

  @action.bound
  public setPlaceholderStartDate(value: Date) {
    this.placeholderStartDate = value
  }

  @action.bound
  public setPlaceholderEndDate(value: Date) {
    this.placeholderEndDate = value
  }

  @action.bound
  public resetPlaceholderDates() {
    this.placeholderStartDate = null
    this.placeholderEndDate = null
  }

  @action.bound
  public nextDate() {
    const { addDays, startOfDay } = this.projectDateStore
    this.setViewingDate(startOfDay(addDays(this.viewingDate, 1)))
  }

  @action.bound
  public prevDate() {
    const { addDays, startOfDay } = this.projectDateStore
    this.setViewingDate(startOfDay(addDays(this.viewingDate, -1)))
  }

  @computed
  public get sitePermitsForCalendar() {
    return this.sitePermitsStore.list.filter(f =>
      areIntervalTimesIntersects(
        {
          startDate: new Date(f.startDate),
          endDate: new Date(f.endDate),
        },
        {
          startDate: this.startDate,
          endDate: this.endDate,
        },
      ),
    )
  }

  public get isLoaderShown(): boolean {
    const { isDeliveriesDisabled, isFormsDisabled, isTrackerDisabled, forms } =
      this.state
    const isBaseLoaderShown =
      !this.sitemapsStore.isDataReceived || !this.closuresStore.isDataReceived

    if (this.isPermitOnly) {
      return (
        isBaseLoaderShown ||
        !this.sitePermitsStore.isDataReceived ||
        !forms.isDataReceived
      )
    }

    return (
      isBaseLoaderShown ||
      !this.announcementsStore.isDataReceived ||
      (!isFormsDisabled && !this.sitePermitsStore.isDataReceived) ||
      (!isDeliveriesDisabled && !this.deliveriesStore.isDataReceived) ||
      (!isTrackerDisabled && !this.activitiesStore.isDataReceived)
    )
  }

  public get state(): MobileInitialState {
    return this.eventsStore.appState
  }
}
