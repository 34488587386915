import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'

import MobileView from '~/client/src/mobile/components/MobileView'
import MobileInitialState from '~/client/src/mobile/stores/MobileInitialState'
import MobileCommonStore from '~/client/src/mobile/stores/ui/MobileCommon.store'
import CompactUserProfile from '~/client/src/shared/components/CompactUserProfile/CompactUserProfile'
import { Loader } from '~/client/src/shared/components/Loader'
import User from '~/client/src/shared/models/User'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'

interface IRouteProps {
  userId?: string
}

interface IProps {
  state?: MobileInitialState
  common?: MobileCommonStore
  projectMembersStore?: ProjectMembersStore
}

const requestedUserIsNotFound = 'Requested user is not found'

const mutationEvents = [
  e.SAVE_COMPANIES,
  e.SAVE_PROJECT_TEAM,
  e.SAVE_PROJECT_ROLE,
  e.SAVE_PROJECT_TRADE,
]

@inject('state', 'common', 'projectMembersStore')
@observer
class UserProfileView extends React.Component<
  IProps & RouteComponentProps<IRouteProps>
> {
  private readonly userId: string = ''

  public constructor(props: IProps & RouteComponentProps<IRouteProps>) {
    super(props)

    this.userId = props.match.params.userId
  }

  public componentDidMount() {
    this.props.common.hideNavBar()
  }

  public render() {
    return <MobileView content={this.content} />
  }

  @computed
  private get shouldRenderLoader(): boolean {
    const { isLoading, loading } = this.props.state

    return isLoading && !mutationEvents.some(e => loading.get(e))
  }

  private get content(): JSX.Element {
    if (this.shouldRenderLoader) {
      return <Loader />
    }

    const {
      common,
      projectMembersStore: { list: projectMembers, getById },
    } = this.props

    const user = getById(this.userId)

    return user ? (
      <CompactUserProfile
        user={user.asJson as User}
        allUsers={projectMembers}
        backClick={common.history.goBack}
      />
    ) : (
      this.errorMessage
    )
  }

  private get errorMessage(): JSX.Element {
    return (
      <div className="row text large center red">{requestedUserIsNotFound}</div>
    )
  }
}

export default withRouter(UserProfileView)
