import * as React from 'react'

import { inject, observer } from 'mobx-react'

import SignUp from '../../shared/components/SignUp/SignUp'
import MobileView from '../components/MobileView'
import MobileCommonStore from '../stores/ui/MobileCommon.store'

interface IProps {
  common?: MobileCommonStore
}

@inject('common')
@observer
export default class SignUpView extends React.Component<IProps> {
  public componentDidMount() {
    this.props.common.hideNavBar()
  }

  public render() {
    return <MobileView className="pa10" content={<SignUp />} />
  }
}
