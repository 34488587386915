import * as React from 'react'

import { Spinner } from '@blueprintjs/core'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'

import MobileCommonStore from '~/client/src/mobile/stores/ui/MobileCommon.store'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import MaterialCategoriesStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'

import MobileInitialState from '../../stores/MobileInitialState'
import DeliveryDetailsWrapper from './components/DeliveryDetailsWrapper'

interface IProps {
  common?: MobileCommonStore
  state?: MobileInitialState
  deliveryDetailsStore?: DeliveryDetailsStore
  locationAttributesStore?: LocationAttributesStore
  deliveriesStore?: DeliveriesStore
  materialCategoryStore?: MaterialCategoriesStore
  materialsStore?: MaterialsStore
}

interface IRouteParams {
  deliveryId: string
}

const SPINNER_SIZE = 50

@inject(
  'common',
  'state',
  'deliveryDetailsStore',
  'locationAttributesStore',
  'deliveriesStore',
  'materialCategoryStore',
  'materialsStore',
)
@observer
class DeliveryDetailsView extends React.Component<
  IProps & RouteComponentProps<IRouteParams>
> {
  private hasDeliveryBeenCreated: boolean = false

  private get isActivityMode() {
    return this.props.state.delivery.compactView.isActivityMode
  }

  private get requestedDeliveryId(): string {
    return this.props.match.params.deliveryId
  }

  public render() {
    const { common, state } = this.props
    const { hideNavBar } = common

    if (this.isLoading) {
      return (
        <div className="full-height row x-center y-center">
          <Spinner size={SPINNER_SIZE} />
        </div>
      )
    }

    return (
      <DeliveryDetailsWrapper
        deliveryId={this.requestedDeliveryId}
        projectId={state.activeProject.id}
        onBackClick={this.backClicked}
        onCreationCallback={this.onCreationCallback}
        onMount={hideNavBar}
      />
    )
  }

  @action.bound
  private backClicked() {
    const { common } = this.props

    if (common.selectedActivity && this.isActivityMode) {
      common.toActivityDetails()
      return
    }

    common.toggleNavBar()

    // We push the delivery details view second time when delivery is created
    // Hence need to take that step back as well
    if (this.hasDeliveryBeenCreated) {
      this.hasDeliveryBeenCreated = false
      return common.goBack(common.displayDeliveriesView, -2)
    }

    common.goBack(common.displayDeliveriesView)
  }

  private get isLoading(): boolean {
    return (
      !this.props.locationAttributesStore.isDataReceived ||
      !this.props.deliveriesStore.isDataReceived ||
      !this.props.materialCategoryStore.isDataReceived ||
      !this.props.materialsStore.isDataReceived
    )
  }

  private onCreationCallback = (id: string) => {
    this.hasDeliveryBeenCreated = true
    this.props.common.displayDeliveryDetailsView(id)
  }
}

export default withRouter(DeliveryDetailsView)
