import { observable } from 'mobx'

import { ActivityStatus, RecentlyUpdatedMode } from '~/client/graph'
import InitialState, {
  IFilters,
  ISharedFilters,
} from '~/client/src/shared/stores/InitialState'

import ActivityListViewMode from '../../shared/enums/ActivityListViewMode'
import { LogisticsFilterType } from '../../shared/enums/LogisticsFilterType'
import LogisticsGroupingOption from '../../shared/enums/LogisticsGroupingOption'
import { LogisticsMapFilterType } from '../../shared/enums/LogisticsMapFilterType'
import UIFilterInfo from '../../shared/stores/substates/UIFilterInfo'

export default class MobileInitialState extends InitialState {
  @observable public activityList = {
    movedToCurrentSectionActivityName: null,
    movingToCurrentSectionTime: null,
  }

  public filters = observable<IMobileFilters>({
    selectedCustomFilterId: null,
    selectedActivityStatusMode: ActivityStatus.InProgress,
    isFilterPageShown: false,
    selectedRecentlyUpdatedMode: RecentlyUpdatedMode.LastP6Update,
    recentlyUpdatedStartDate: null,
    recentlyUpdatedEndDate: null,
    viewMode: ActivityListViewMode.ASSIGNED,
    currentDate: new Date(),
  })

  @observable public isNotificationsArchiveModeActive: boolean = false

  public logisticsFilters = observable<IFilters>({
    searchKey: '',
    groupingKey: LogisticsGroupingOption.APP,
    fieldsMap: {
      [LogisticsFilterType.Status]: new UIFilterInfo(),
      [LogisticsFilterType.Location]: new UIFilterInfo(),
      [LogisticsFilterType.Company]: new UIFilterInfo(),
      [LogisticsFilterType.App]: new UIFilterInfo(),
      [LogisticsFilterType.ResponsibleContact]: new UIFilterInfo(),
    },
  })

  public logisticsMapFilters = observable<IFilters>({
    searchKey: '',
    fieldsMap: {
      [LogisticsMapFilterType.Location]: new UIFilterInfo(),
      [LogisticsMapFilterType.Company]: new UIFilterInfo(),
      [LogisticsMapFilterType.App]: new UIFilterInfo(),
      [LogisticsMapFilterType.ResponsibleContact]: new UIFilterInfo(),
    },
  })

  public formsMapFilters = observable<IFilters>({
    searchKey: '',
    fieldsMap: {
      [LogisticsMapFilterType.Location]: new UIFilterInfo(),
      [LogisticsMapFilterType.Company]: new UIFilterInfo(),
      [LogisticsMapFilterType.ResponsibleContact]: new UIFilterInfo(),
      [LogisticsMapFilterType.Type]: new UIFilterInfo(),
    },
  })

  public formsFilters = observable<IFilters>({
    searchKey: '',
    groupingKey: LogisticsGroupingOption.TYPE,
    fieldsMap: {
      [LogisticsFilterType.Status]: new UIFilterInfo(),
      [LogisticsFilterType.Location]: new UIFilterInfo(),
      [LogisticsFilterType.Company]: new UIFilterInfo(),
      [LogisticsFilterType.Type]: new UIFilterInfo(),
      [LogisticsFilterType.ResponsibleContact]: new UIFilterInfo(),
    },
  })
}

export interface IMobileFilters extends ISharedFilters {
  selectedCustomFilterId: string
  isFilterPageShown: boolean
  viewMode: ActivityListViewMode
  currentDate: Date
}
