import MobileEventStore from '~/client/src/mobile/stores/EventStore/MobileEvents.store'
import MobileRootStore from '~/client/src/mobile/stores/MobileRoot.store'
import Activity from '~/client/src/shared/models/Activity'
import BaseNotification from '~/client/src/shared/models/Notification'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import { isDeliveryType } from '~/client/src/shared/types/NotificationTypes'

export default function (
  eventsStore: MobileEventStore,
  rootStore: MobileRootStore,
) {
  eventsStore.on(e.SAVE_PASSWORD_SUCCESS, () => {
    return {
      dispatchN: [
        [e.DISPLAY_DEFAULT_MOBILE_PAGE, true],
        [e.INIT_APP_3],
        [e.SEND_WELCOME_EMAIL_TO_CURRENT_USER],
      ],
    }
  })

  eventsStore.on(e.SCHEDULE_LOADED, state => {
    return {
      dispatch: [e.GET_SCHEDULE, state.filters.currentDate || new Date()],
    }
  })

  eventsStore.on(
    e.UPDATE_ACTIVITY_COMPANY_STATUS_WITH_NOTIFICATION,
    (
      state,
      activityCode,
      date: number,
      companies: string[],
      percentComplete: number,
      manpower: number,
    ) => {
      const activity = rootStore.activitiesStore.byId.get(activityCode)

      return {
        flow: {
          startWith: [
            [
              e.UPDATE_ACTIVITY_COMPANIES_STATUSES,
              activityCode,
              date,
              companies,
              percentComplete,
              manpower,
            ],
          ],
          rules: [
            {
              shouldTerminate: true,
              when: 'on',
              event: e.UPDATE_ACTIVITY_COMPANY_STATUS_SUCCESS,
              dispatch: [
                e.SHOW_MOVED_TO_CURRENT_SECTION_TOOLTIP,
                activity as any,
              ],
            },
          ],
        },
      }
    },
  )

  const MOVING_TO_CURRENT_SECTION_HINT_DURATION = 3000

  eventsStore.on(
    e.SHOW_MOVED_TO_CURRENT_SECTION_TOOLTIP,
    (state, activity: Activity) => {
      state.activityList.movedToCurrentSectionActivityName = activity.name
      state.activityList.movingToCurrentSectionTime = new Date()

      setTimeout(() => {
        state.activityList.movedToCurrentSectionActivityName = null
        state.activityList.movingToCurrentSectionTime = null
      }, MOVING_TO_CURRENT_SECTION_HINT_DURATION)
    },
  )

  eventsStore.on(e.HANDLE_SAME_PROJECT_SELECTION, () => {
    return {
      dispatch: [e.DISPLAY_DEFAULT_MOBILE_PAGE, true],
    }
  })

  eventsStore.on(e.CHANGE_DELIVERY_STATUS_SUCCESS, state => {
    state.resetToDefaultCompactDeliveryView()
  })

  eventsStore.on(
    e.VIEW_NOTIFICATION,
    (state, notification: BaseNotification) => {
      if (notification.activityObjectId) {
        rootStore.activitiesStore.select(notification.activityObjectId)
      }

      if (isDeliveryType(notification.type)) {
        const delivery = rootStore.deliveriesStore.byId.get(
          notification.entityId,
        )
        rootStore.deliveryDetailsStore.setViewMode(delivery)
      }

      rootStore.common.displayNotificationDetails(notification)
      return {
        dispatch: [e.MARK_NOTIFICATION_AS_READ, notification],
      }
    },
  )

  eventsStore.on(e.UPDATE_USER_LAST_ACTIVITY_LIST_MOBILE_FILTERS, state => {
    const {
      userActiveProjectSettings,
      mobileActivityFiltersByFilterType: filters,
    } = state

    userActiveProjectSettings.lastMobileActivityFilters = filters

    return {
      dispatch: [e.SAVE_USER_PROJECTS, [userActiveProjectSettings.toDto()]],
    }
  })

  eventsStore.on(e.RESET_ALL_FILTERS, () => {
    // only desktop event handler
    return
  })
}
