import * as React from 'react'

import { Spinner } from '@blueprintjs/core'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { QRCode } from 'react-qrcode-logo'
import { RouteComponentProps, withRouter } from 'react-router'

import { DeliveryStatus, IDeliveryTicketResponse } from '~/client/graph'
import MobileFileInput from '~/client/src/mobile/components/FileInput/MobileFileInput'
import MobileView from '~/client/src/mobile/components/MobileView'
import MobileEventStore from '~/client/src/mobile/stores/EventStore/MobileEvents.store'
import MobileCommonStore from '~/client/src/mobile/stores/ui/MobileCommon.store'
import DeliveryTicketStore from '~/client/src/mobile/views/DeliveryTicket/DeliveryTicket.store'
import * as Icons from '~/client/src/shared/components/Icons'
import * as TagIcon from '~/client/src/shared/components/TagIcon'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'
import capitalize from '~/client/src/shared/utils/capitalizeText'

import Colors from '~/client/src/shared/theme.module.scss'

import './DeliveryTicket.scss'

const CONTACTS_SEPARATOR = ' • '
const EMPTY_CONTACT_PLACEHOLDER = '-'

// translated

interface IProps {
  common?: MobileCommonStore
  eventsStore?: MobileEventStore
}

interface IRouteParams {
  deliveryId: string
}

interface ITicketFields {
  icon: JSX.Element
  fieldName: string
  value: string
  action?: JSX.Element
}

const fieldNames = {
  deliveryCode: 'deliveryCode',
  address: 'address',
  date: 'date',
  materials: 'materials',
  vehicle: 'vehicle',
  onSiteContactNames: 'onSiteContactNames',
  inspectorName: 'inspectorName',
  driverPhoneNumbers: 'driverPhoneNumbers',
  gate: 'gate',
  route: 'route',
  zone: 'zone',
  equipments: 'equipments',
  building: 'building',
  level: 'level',
  area: 'area',
}

const defaultDisplayName = {
  deliveryId: 'Delivery ID',
  inspector: 'Inspector',
  driverPhoneNumbers: 'Driver Phone Numbers',
  onSiteContacts: 'On-Site Contacts',
}

const googleMapsUrl = (address: string) =>
  `https://maps.google.com/?q=${address}`

@inject('common', 'eventsStore')
@observer
class DeliveryTicket extends React.Component<
  IProps & RouteComponentProps<IRouteParams>
> {
  private readonly store: DeliveryTicketStore = null

  public constructor(props: IProps & RouteComponentProps<IRouteParams>) {
    super(props)

    this.store = new DeliveryTicketStore(
      props.eventsStore,
      props.match.params.deliveryId,
    )
  }

  public componentDidMount() {
    this.props.common.hideNavBar()
    this.store.requestDeliveryTicket()
  }

  public render() {
    return <MobileView content={this.content} />
  }

  public get content() {
    if (!this.ticket) {
      return this.store.isTicketCorrupted
        ? this.renderWarning()
        : this.renderLoader()
    }

    const {
      projectName,
      isCustomActionIncluded,
      customActionButtonText,
      customActionText,
      customActionUrl,
      deliveryCode,
      status,
      date,
      timeRange,
      gate,
    } = this.ticket

    const {
      deliveryTicket,
      onSiteLogistics,
      deliveryDetails,
      showAtEntryGate,
      showAtX,
    } = Localization.translator

    return (
      <div className="col delivery-ticket">
        <Icons.StruxHubFull className="ml20 mb20" />
        <div className="orange text center left bold-font pb10 huge">
          {deliveryTicket} {deliveryCode}
        </div>
        <div className="text center large mb10">
          <span
            className={classList({
              status: true,
              'changed-status': status === DeliveryStatus.Changed,
            })}
          >
            {status}
          </span>
        </div>
        <div className="text center header bold">{projectName}</div>
        <div className="text semi-transparent center extra-large">{date}</div>
        <div className="text semi-transparent center extra-large mb5">
          {timeRange}
        </div>
        <div className="ma-x-auto">
          <QRCode
            value={location.href}
            logoImage="/static/icons/strux-hub-dark-with-background.svg"
            size={200}
            logoWidth={62}
            fgColor={ThemeMode.getHEXColor(Colors.neutral0)}
          />
        </div>
        <div className="text semi-transparent center extra-large">
          {gate ? showAtX(gate) : showAtEntryGate}
        </div>

        <div className="pa20">
          {isCustomActionIncluded && (
            <div className="pt20 col">
              <span className="text extra-large orange center pb15">
                {customActionText}
              </span>
              <a
                className="ba-primary-blue brada4 large ma-x-auto pa10 primary-blue text"
                href={customActionUrl}
                target="_blank"
              >
                {customActionButtonText}
              </a>
            </div>
          )}

          <div className="pt20">
            <div className="orange text huge left bold-font pb20">
              {deliveryDetails}
            </div>
            {this.renderDeliveryDetails(this.deliveryDetailsData)}
          </div>
          <div className="orange text huge left bold-font pb20">
            {onSiteLogistics}
          </div>
          {this.renderOnSiteLogistics()}
        </div>
      </div>
    )
  }

  private renderDeliveryDetails = (data: ITicketFields[]) => {
    if (!this.ticket) {
      return
    }

    return data
      .filter(field => field.value)
      .map((field, index) => {
        return (
          <div key={index} className="mb20">
            <div className="row text details-field-name uppercase lp15 bold no-wrap mb3">
              {field.icon} {field.fieldName}
            </div>
            <div className="text details-field-value extra-large">
              {field.value}
            </div>
            {field.action && <div>{field.action}</div>}
          </div>
        )
      })
  }

  private renderLoader() {
    return <Spinner className="ma-auto" size={25} />
  }

  private renderWarning() {
    return (
      <div className="ma-auto">
        {Localization.translator.invalidDeliveryTicket} =(
      </div>
    )
  }

  private renderOnSiteLogistics() {
    if (!this.ticket) {
      return
    }

    const { mapsUrls } = this.store
    let logisticsIndex = 0
    return (
      <>
        {mapsUrls.map((mapUrl, index) => {
          if (!mapUrl) {
            return null
          }

          return (
            <div key={index}>
              <MobileFileInput
                id={`map-${index}`}
                name=""
                value={mapUrl}
                isReadonly={true}
                textClassName="hidden"
                isFlexPreview={true}
              />
            </div>
          )
        })}
        {this.onSiteLogisticsData.map(data => {
          if (!data.some(x => x.value)) {
            return null
          }
          return (
            <div key={logisticsIndex++} className="row align-start">
              <div className="text large bold orange no-grow mr12">
                {logisticsIndex}.
              </div>
              <div>{this.renderDeliveryDetails(data)}</div>
            </div>
          )
        })}
      </>
    )
  }

  private get deliveryDetailsData(): ITicketFields[] {
    const {
      requesterCompany,
      deliveryCode,
      address,
      date,
      driverPhoneNumbers,
      inspectorCompany,
      inspectorContacts,
      inspectorName,
      materials,
      onSiteContacts,
      timeRange,
      vehicle,
    } = this.ticket
    const { getDirections } = Localization.translator

    return [
      {
        icon: <Icons.Docs className="details-icon" />,
        fieldName: this.getDisplayName(
          fieldNames.deliveryCode,
          defaultDisplayName.deliveryId,
        ),
        value: deliveryCode + (requesterCompany ? ': ' + requesterCompany : ''),
      },
      {
        icon: <Icons.PinOnMap className="details-icon" />,
        fieldName: this.getDisplayName(fieldNames.address),
        value: address,
        action: (
          <a
            className="text bold extra-large link"
            href={googleMapsUrl(address)}
            target="_blank"
          >
            {getDirections}
          </a>
        ),
      },
      {
        icon: <Icons.Calendar className="details-icon" />,
        fieldName: this.getDisplayName(fieldNames.date),
        value: date + '\n' + timeRange,
      },
      {
        icon: <Icons.Material className="details-icon" />,
        fieldName: this.getDisplayName(fieldNames.materials),
        value: (materials || [])
          .map(material =>
            [
              material.name,
              material.category,
              `${material.quantity || 0}-${
                material.unit || Localization.translator.noUnit
              }`,
              material.location,
              material.note,
            ]
              .filter(m => !!m)
              .join('\n'),
          )
          .join('\n'),
      },
      {
        icon: <Icons.Truck className="details-icon" />,
        fieldName: this.getDisplayName(fieldNames.vehicle),
        value: vehicle,
      },
      {
        icon: <Icons.FilledStar className="details-icon" />,
        fieldName: this.getDisplayName(
          fieldNames.onSiteContactNames,
          defaultDisplayName.onSiteContacts,
        ),
        value: (onSiteContacts || [])
          .map(
            contact =>
              `${contact.name} (${
                contact.company || Localization.translator.noCompany
              })` + this.getParsedContacts(contact.contact),
          )
          .join('\n\n'),
      },
      {
        icon: <Icons.FilledStar className="details-icon" />,
        fieldName: this.getDisplayName(
          fieldNames.inspectorName,
          defaultDisplayName.inspector,
        ),
        value: inspectorName
          ? `${inspectorName} (${
              inspectorCompany || Localization.translator.noCompany
            })` + this.getParsedContacts(inspectorContacts)
          : EMPTY_CONTACT_PLACEHOLDER,
      },
      {
        icon: <Icons.Mobile className="details-icon" />,
        fieldName: this.getDisplayName(
          fieldNames.driverPhoneNumbers,
          defaultDisplayName.driverPhoneNumbers,
        ),
        value: driverPhoneNumbers?.join(', '),
      },
    ]
  }

  private get onSiteLogisticsData(): ITicketFields[][] {
    return [
      [
        {
          icon: <Icons.FilledGate className="details-icon" />,
          fieldName: this.getDisplayName(fieldNames.gate),
          value: this.ticket.gate,
        },
      ],
      [
        {
          icon: <Icons.RouteReport className="details-icon" />,
          fieldName: this.getDisplayName(fieldNames.route),
          value: this.ticket.route,
        },
      ],
      [
        {
          icon: <TagIcon.Zone className="details-icon" />,
          fieldName: this.getDisplayName(fieldNames.zone),
          value: this.ticket.zone,
        },
        {
          icon: <TagIcon.Equipment className="details-icon" />,
          fieldName: this.getDisplayName(fieldNames.equipments),
          value: this.ticket.equipments,
        },
      ],
      [
        {
          icon: <TagIcon.Building className="details-icon" />,
          fieldName: this.getDisplayName(fieldNames.building),
          value: this.ticket.building,
        },
        {
          icon: <TagIcon.Level className="details-icon" />,
          fieldName: this.getDisplayName(fieldNames.level),
          value: this.ticket.level,
        },
        {
          icon: <TagIcon.Area className="details-icon" />,
          fieldName: this.getDisplayName(fieldNames.area),
          value: this.ticket.area,
        },
      ],
    ]
  }

  private get ticket(): IDeliveryTicketResponse {
    return this.store.ticket
  }

  private getParsedContacts(contacts: string): string {
    return (
      '\n' +
      contacts
        ?.split(CONTACTS_SEPARATOR)
        .filter(_ => _ !== EMPTY_CONTACT_PLACEHOLDER)
        .join('\n')
    )
  }

  private getDisplayName(fieldName: string, defaultName?: string): string {
    const { displayNames } = this.ticket
    return (
      displayNames.find(dn => dn.key === fieldName)?.name ||
      defaultName ||
      capitalize(fieldName)
    )
  }
}

export default withRouter(DeliveryTicket)
