import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import MenuCloser from '~/client/src/shared/components/MenuCloser'
import { IFilters } from '~/client/src/shared/stores/InitialState'

import EntityNameFilterStore from './EntityNameFilter.store'

import './EntityNameFilter.scss'

// localization: translated

const doneTypingInterval = 300
const SEARCH_SIZE = 18

interface IEntityNameFilter {
  filters: IFilters
  onShowChanged?: (isShown: boolean) => void
  placeholder?: string
  className?: string
  forceClose?: boolean
  openOnLeft?: boolean
}

@observer
export default class EntityNameFilter extends React.Component<IEntityNameFilter> {
  public static defaultProps = {
    className: '',
  }

  @observable private typingTimer: number
  private readonly store: EntityNameFilterStore

  public constructor(props: IEntityNameFilter) {
    super(props)

    this.store = new EntityNameFilterStore(props.filters, props.onShowChanged)
  }

  public componentDidUpdate() {
    if (this.props.forceClose) {
      this.store.isShown = false
    }
  }

  public render() {
    const isActive = this.store.isShown || !!this.store.searchKey.length
    const { className } = this.props

    return (
      <MenuCloser
        key={'search'}
        className={classList({
          'entity-name-filter': true,
          [className]: !!className,
          'selected ba-palette-brand-primary bg-blue-light': isActive,
          'ba-palette-brand-lighter bg-white': !isActive,
        })}
        closeMenu={this.store.close}
      >
        <div className="row search-container">
          <Icon
            size={SEARCH_SIZE}
            icon={IconNames.SEARCH}
            onClick={this.store.toggle}
            className="row x-center ml4"
          />
          {this.store.isShown ? (
            <input
              className={classList({
                'search bg-transparent mx8': true,
                shown: this.store.isShown,
              })}
              type="search"
              autoFocus={true}
              value={this.store.searchKey}
              onChange={this.updateSearchKey}
              onKeyDown={this.keyDownAction}
              onKeyUp={this.keyUpAction}
            />
          ) : (
            <div
              className={classList({
                'text large': true,
                'primary-blue mx8': isActive,
              })}
              onClick={this.store.toggle}
            >
              {this.store.searchKey}
            </div>
          )}
        </div>
      </MenuCloser>
    )
  }

  @action.bound
  private updateSearchKey(event: React.SyntheticEvent<HTMLInputElement>) {
    this.store.searchKey = event.currentTarget.value
  }

  private keyUpAction = () => {
    clearTimeout(this.typingTimer)

    this.typingTimer = window.setTimeout(this.store.update, doneTypingInterval)
  }

  private keyDownAction = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      this.store.update()
      this.store.close()
    }
    clearTimeout(this.typingTimer)
  }
}
