import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'

import MobileFileInput from '~/client/src/mobile/components/FileInput/MobileFileInput'
import MobileView from '~/client/src/mobile/components/MobileView'
import MobileCommonStore from '~/client/src/mobile/stores/ui/MobileCommon.store'
import ContentObjectViewBase from '~/client/src/shared/components/ContentObject/ContentObjectViewBase'
import DaySeparator from '~/client/src/shared/components/DaySeparator'
import RfiThread from '~/client/src/shared/components/RfiEntry/RfiThread'
import RfiIcon from '~/client/src/shared/components/RfiIcon/RfiIcon'
import RootStore from '~/client/src/shared/stores/Root.store'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import RfisStore from '~/client/src/shared/stores/domain/Rfis.store'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'

interface IProps {
  contentObjectsStore: RfisStore
  commonStore: MobileCommonStore
  activitiesStore: ActivitiesStore
  slackBarStore: SlackBarStore
}

interface IRouteParams {
  rfiId: string
}

function mapToProps(store: RootStore) {
  return {
    activitiesStore: store.activitiesStore,
    commonStore: store.common,
    contentObjectsStore: store.rfisStore,
    slackBarStore: store.slackBarStore,
  }
}

@inject(mapToProps)
@observer
class RfiView extends ContentObjectViewBase<
  IProps & RouteComponentProps<IRouteParams>
> {
  protected className: string = 'rfi'

  @action.bound
  public goBack() {
    const store = this.props.slackBarStore
    if (store.shouldShowPreview) {
      return store.hidePreview()
    }

    const { commonStore } = this.props
    commonStore.toggleNavBar()
    commonStore.history.goBack()
  }

  @action.bound
  public goToActivityDetails() {
    this.props.commonStore.displayActivityDetailsView(this.activity.code)
  }

  public render() {
    return (
      <MobileView
        content={this.content}
        footer={this.footer}
        header={this.header}
        imagePreview={this.imagePreview}
      />
    )
  }

  public get content() {
    const currEntryDate = new Date(this.contentObject.createdAt)

    return (
      <div className="activity-log">
        <DaySeparator date={currEntryDate} />
        <RfiThread
          rfi={this.contentObject}
          hideButtons={true}
          FileInputType={MobileFileInput}
        />
      </div>
    )
  }

  public componentDidMount() {
    this.props.commonStore.hideNavBar()
    this.contentObjectId = this.props.match.params.rfiId
  }

  protected caption = () => 'RFI'
  protected replyToCaption = () => 'Reply to this RFI'

  protected getIcon(): React.ReactNode {
    return <RfiIcon rfi={this.contentObject} />
  }
}

export default withRouter(RfiView)
