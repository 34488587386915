import * as React from 'react'

import { observer } from 'mobx-react'

import PhotoView from '~/client/src/mobile/views/Documents/components/PhotoGallery/components/Photo/Photo'
import PhotoActivityCaption from '~/client/src/mobile/views/Documents/components/PhotoGallery/components/PhotoActivityCaption'
import Activity from '~/client/src/shared/models/Activity'
import Photo from '~/client/src/shared/models/Photo'

interface IActivityPhotosBlockProps {
  activity: Activity
  photos: Photo[]
  rightSideContent: string
}

@observer
export default class ActivityPhotosBlock extends React.Component<IActivityPhotosBlockProps> {
  public render() {
    const { activity, photos, rightSideContent } = this.props

    return (
      <div>
        <PhotoActivityCaption
          activity={activity}
          rightSideContent={rightSideContent}
        />
        <div className="photo-gallery-photos">
          {photos.map(photo => {
            return (
              <div key={photo.id}>
                <PhotoView width={60} height={80} photo={photo} />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
