import * as React from 'react'

import { inject, observer } from 'mobx-react'

import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import VisitorSignUp from '~/client/src/shared/components/VisitorSignUp/VisitorSignUp'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import MobileView from '../components/MobileView'
import MobileCommonStore from '../stores/ui/MobileCommon.store'

interface IProps {
  common?: MobileCommonStore
}

@inject('common')
@observer
export default class VisitorSignUpView extends React.Component<IProps> {
  public componentDidMount() {
    this.props.common.hideNavBar()
  }

  public render() {
    return (
      <MobileView
        className="pa10"
        header={this.renderHeader()}
        content={<VisitorSignUp />}
      />
    )
  }

  private renderHeader = (): JSX.Element => {
    return (
      <div className="row x-end mb15">
        <BaseActionButton
          className="inverse-scale-blue-theme"
          title={Localization.translator.signIn}
          isEnabled={true}
          onClick={this.props.common.displayLoginView}
        />
      </div>
    )
  }
}
