import * as React from 'react'

import * as Layout from '~/client/src/shared/components/Layout'

interface IMobileViewProps {
  header?: React.ReactElement<any>
  content?: React.ReactElement<any>
  footer?: React.ReactElement<any>
  imagePreview?: React.ReactElement<any>
  className?: string
}

export default class MobileView<T> extends React.Component<
  IMobileViewProps & T
> {
  public render() {
    const { View, Header, Content, Footer } = Layout
    return (
      <View className={this.props.className}>
        <Header>{this.renderHeader()}</Header>
        {this.renderImagePreview()}
        <Content scrollable={true}>{this.renderContent()}</Content>
        <Footer>{this.renderFooter()}</Footer>
      </View>
    )
  }

  public renderHeader() {
    const { header } = this.props
    return header ? header : null
  }

  public renderContent() {
    const { content } = this.props
    return content ? content : null
  }

  public renderFooter() {
    const { footer } = this.props
    return footer ? footer : null
  }

  public renderImagePreview() {
    const { imagePreview } = this.props
    return imagePreview ? imagePreview : null
  }
}
