import * as React from 'react'

import { inject, observer } from 'mobx-react'

import BottomNavigationNavBar from '~/client/src/mobile/components/BottomNavigationNavBar/BottomNavigationNavBar'
import MobileFileInput from '~/client/src/mobile/components/FileInput/MobileFileInput'
import NotificationCountLabel from '~/client/src/mobile/components/NotificationCountLabel'
import MobileEventStore from '~/client/src/mobile/stores/EventStore/MobileEvents.store'
import MobileActivityListStore from '~/client/src/mobile/stores/ui/ActivityList.store'
import MobileCommonStore from '~/client/src/mobile/stores/ui/MobileCommon.store'
import ActivityListFilterHeader from '~/client/src/mobile/views/ActivityList/components/ActivityListFilter/ActivityListFilterHeader'
import CollapseControl from '~/client/src/mobile/views/ActivityList/components/CollapseControl/CollapseControl'
import ActivitiesTree from '~/client/src/shared/components/ActivitiesTree/ActivitiesTree'
import CompactCustomNodeWrapper from '~/client/src/shared/components/ActivitiesTree/components/ActivitiesTreeNode/CompactCustomNodeWrapper'
import CompactLocationContainerNode from '~/client/src/shared/components/ActivitiesTree/components/ActivitiesTreeNode/CompactLocationContainerNode'
import DayBar from '~/client/src/shared/components/DayBar'
import * as Icons from '~/client/src/shared/components/Icons'
import {
  Content,
  Footer,
  Header,
  View,
} from '~/client/src/shared/components/Layout'
import Spinner from '~/client/src/shared/components/Spinner/Spinner'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import FlagsStore from '~/client/src/shared/stores/domain/Flags.store'
import HierarchyConfigurationStore from '~/client/src/shared/stores/domain/HierarchyConfiguration.store'
import LocationsStore from '~/client/src/shared/stores/domain/Locations.store'
import RfisStore from '~/client/src/shared/stores/domain/Rfis.store'
import ScheduleCommentsStore from '~/client/src/shared/stores/domain/ScheduleComments.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import ActivityListComponentStore from './ActivityList.store'
import MovedToCurrentSectionTooltip from './components/MovedToCurrentSectionTooltip/MovedToCurrentSectionTooltip'
import NoActivitiesBanner from './components/NoActivitiesBanner/NoActivitiesBanner'

const ARROW_HEIGHT = 15

interface IProps {
  common?: MobileCommonStore
  activityListStore?: MobileActivityListStore
  eventsStore?: MobileEventStore
  locationStore?: LocationsStore
  activitiesStore?: ActivitiesStore
  rfisStore?: RfisStore
  companiesStore?: CompaniesStore
  flagsStore?: FlagsStore
  deliveriesStore?: DeliveriesStore
  scheduleCommentsStore?: ScheduleCommentsStore
  statusUpdatesStore?: StatusUpdatesStore
  activityFiltersStore?: ActivityFiltersStore
  hierarchyConfigurationStore?: HierarchyConfigurationStore
  projectDateStore?: ProjectDateStore
}

@inject(
  'common',
  'activityListStore',
  'eventsStore',
  'locationStore',
  'activitiesStore',
  'rfisStore',
  'companiesStore',
  'flagsStore',
  'deliveriesStore',
  'scheduleCommentsStore',
  'statusUpdatesStore',
  'activityFiltersStore',
  'hierarchyConfigurationStore',
  'projectDateStore',
)
@observer
export default class ActivityList extends React.Component<IProps> {
  private readonly store: ActivityListComponentStore
  private clearPostEventCallback: () => void

  public constructor(props: IProps) {
    super(props)

    this.store = new ActivityListComponentStore(
      props.activityFiltersStore,
      props.locationStore,
      props.projectDateStore,
      props.eventsStore,
      props.companiesStore,
      props.activityListStore,
      props.activitiesStore,
      props.eventsStore,
      props.rfisStore,
      props.flagsStore,
      props.deliveriesStore,
      props.scheduleCommentsStore,
      props.statusUpdatesStore,
      props.hierarchyConfigurationStore,
    )

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.store.onTreeRequest,
    )

    this.store.calculateActivityTree(true)
  }

  public componentWillUnmount() {
    if (this.clearPostEventCallback) {
      this.clearPostEventCallback()
    }
  }

  public componentDidMount() {
    this.commonStore.hideNavBar()
  }

  public get commonStore(): MobileCommonStore {
    return this.props.common
  }

  public get activityListStore(): MobileActivityListStore {
    return this.props.activityListStore
  }

  private get activityList() {
    if (this.store.isTreeLoading) {
      return this.loader
    }

    const { activitiesCount, isFilterApplied } = this.activityListStore

    const { activitiesTree, changeContainerExpandState, currentDate } =
      this.store

    return activitiesCount ? (
      <ActivitiesTree
        nodes={activitiesTree}
        viewDate={currentDate}
        CustomNodeWrapperType={CompactCustomNodeWrapper}
        LocationContainerNodeType={CompactLocationContainerNode}
        changeContainerExpandState={changeContainerExpandState}
        FileInputType={MobileFileInput}
        compactActivityListStore={this.store}
      />
    ) : (
      <NoActivitiesBanner
        isFilterApplied={isFilterApplied}
        onClickClearFilter={this.resetFilters}
      />
    )
  }

  public resetFilters = () => {
    this.props.eventsStore.appState.filters.selectedCustomFilterId = null
    this.activityListStore.saveFilterValuesToState(null, null, null)
  }

  private get loader() {
    return <Spinner size={50} className="loader" />
  }

  public render() {
    const { isLoading } = this.activityListStore

    const {
      areAllRootNodesCollapsed,
      setDate,
      prevDate,
      nextDate,
      onSelectModeToggled,
      currentDate,
      setExpandType,
      expandType,
    } = this.store

    return (
      <View className="overflow-hidden">
        <div className="daily-mobile-component">
          <Header>
            <div className="row bb-light-grey pa20 activity-list-header">
              <Icons.Activities className="no-grow indication-click" />
              <DayBar
                leftClicked={prevDate}
                rightClicked={nextDate}
                isArrowsHidden={true}
                shouldIconRender={false}
                arrowsHeight={ARROW_HEIGHT}
                currentStartDate={currentDate}
                isOneDayMode={true}
                setDate={setDate}
              />
              <NotificationCountLabel />
            </div>
            {!isLoading && (
              <>
                <ActivityListFilterHeader
                  activityListComponentStore={this.store}
                />
                <CollapseControl
                  expandType={expandType}
                  onExpandTypeChanged={setExpandType}
                  areAllCollapsed={areAllRootNodesCollapsed}
                  onModeToggled={onSelectModeToggled}
                />
              </>
            )}
          </Header>
          <Content scrollable={true}>
            {isLoading ? this.loader : this.activityList}
          </Content>
          <MovedToCurrentSectionTooltip />
          <Footer>
            <BottomNavigationNavBar />
          </Footer>
        </div>
      </View>
    )
  }
}
