import * as React from 'react'

import { Radio, RadioGroup } from '@blueprintjs/core'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import ActivityFiltersPageStore from '../ActivityFiltersPage.store'
import CustomFilterLabel from './CustomFilterLabel'
import CustomFiltersSelectorStore from './CustomFiltersSelector.store'

export interface IProps {
  filtersPageStore: ActivityFiltersPageStore
}

@inject('activityCodesStore')
@observer
export default class CustomFiltersSelector extends React.Component<IProps> {
  private readonly store: CustomFiltersSelectorStore

  public constructor(props: IProps) {
    super(props)

    this.store = new CustomFiltersSelectorStore(props.filtersPageStore)
  }

  public render() {
    const {
      isCustomFilterEditingDisabled,
      customFilters,
      defaultCustomCompanyFilter,
      selectedCustomFilterId,
    } = this.props.filtersPageStore

    return (
      <div>
        <div className="row no-flex-children x-between y-center bb-light-grey pb10">
          <div className="text extra-large bold">My Filters</div>
          <div
            className={classList({
              'select-all-button white-btn': true,
              'inactive-element': isCustomFilterEditingDisabled,
            })}
            onClick={this.store.editCustomFilter}
          >
            Edit
          </div>
        </div>
        <div className="mb20">
          <RadioGroup
            className="theme-radio-group"
            onChange={this.selectCustomFilter}
            selectedValue={selectedCustomFilterId}
          >
            {defaultCustomCompanyFilter && (
              <Radio
                key={defaultCustomCompanyFilter.id}
                value={defaultCustomCompanyFilter.id}
              >
                <CustomFilterLabel customFilter={defaultCustomCompanyFilter} />
              </Radio>
            )}
            {customFilters.map(filter => (
              <Radio key={filter.id} value={filter.id}>
                <CustomFilterLabel customFilter={filter} />
              </Radio>
            ))}
          </RadioGroup>
        </div>
      </div>
    )
  }

  @action.bound
  private selectCustomFilter(event) {
    this.store.selectCustomFilter(event.target.value)
  }
}
