import * as React from 'react'

import { IAppConfig } from '~/client/src/shared/Config'
import { LogoWithTitle } from '~/client/src/shared/components/Icons'

import './SidebarFooter.scss'

export default function SidebarFooter(props: { config: IAppConfig }) {
  return (
    <div className="sidebar-footer bg-white col y-end no-grow">
      <div className="container">
        <LogoWithTitle className="logo col no-grow" />
        <div className="build-info col text line-16">
          <div>Version: {props.config.VERSION}</div>
          <div>Revision: {props.config.REVISION}</div>
          <div>Build: {props.config.BUILD}</div>
        </div>
      </div>
    </div>
  )
}
