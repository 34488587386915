import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import ActivityPhotosBlock from '~/client/src/mobile/views/Documents/components/PhotoGallery/components/ActivityPhotosBlock'
import PhotoSortingTypes from '~/client/src/shared/enums/PhotoSortingTypes'
import Photo from '~/client/src/shared/models/Photo'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import PhotosStore from '~/client/src/shared/stores/domain/Photos.store'

import './PhotoGallery.scss'

import { Icon } from '@blueprintjs/core'

import Activity from '~/client/src/shared/models/Activity'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

const thereIsNoPhotoPassingFilters = 'There is no photo passing filters'

interface IPhotoGalleryProps {
  photosStore?: PhotosStore
  activitiesStore?: ActivitiesStore
  projectDateStore?: ProjectDateStore
}

@inject('photosStore', 'activitiesStore', 'projectDateStore')
@observer
export default class PhotoGallery extends React.Component<IPhotoGalleryProps> {
  public render() {
    const { selectedSorting } = this.props.photosStore

    let photosView

    if (selectedSorting === PhotoSortingTypes.DATE) {
      photosView = this.getViewByDate()
    } else if (selectedSorting === PhotoSortingTypes.ACTIVITY) {
      photosView = this.getViewByActivity()
    } else if (selectedSorting === PhotoSortingTypes.LOCATION) {
      photosView = this.getViewByLocation()
    }

    return <div className="photo-gallery-component">{photosView}</div>
  }

  @computed
  public get activeActivitiesWithPhotos() {
    return this.props.activitiesStore.activeActivities
      .filter(
        activity => this.props.photosStore.photosActivityIdMap[activity.code],
      )
      .sort(this.byActivityPlannedDueDate)
  }

  @computed
  public get futureActivitiesWithPhotos() {
    return this.props.activitiesStore.futureActivities
      .filter(
        activity => this.props.photosStore.photosActivityIdMap[activity.code],
      )
      .sort(this.byActivityPlannedStartDate)
  }

  @computed
  public get completedActivitiesWithPhotos() {
    return this.props.activitiesStore.completedActivities
      .filter(
        activity => this.props.photosStore.photosActivityIdMap[activity.code],
      )
      .sort(this.byActivityActualDueDate)
  }

  private getDateHeader(date: Date): string {
    const { getPronoun, getMonthDayAndYearToDisplay } =
      this.props.projectDateStore
    const datePronoun = getPronoun(date)
    return (
      (datePronoun ? datePronoun + ', ' : '') +
      getMonthDayAndYearToDisplay(date)
    )
  }

  private getTitleClass(dateHeader: string) {
    return 'photo-gallery-date' + (dateHeader === 'Today' ? ' today' : '')
  }

  @computed
  public get photosByDateGroupedByActivity(): Array<{
    date: Date
    activities: Array<{ activity: Activity; photos: Photo[] }>
  }> {
    const { photosByDate } = this.props.photosStore
    const { activitiesStore } = this.props

    return photosByDate
      .sort(this.byPhotoDescendingDate)
      .reduce((entries, batch) => {
        const photosByActivitiesMap = batch.photos.reduce((map, photo) => {
          if (!photo.url) {
            return map
          }

          const activity = activitiesStore.byId.get(photo.activityObjectId)
          if (!activity) {
            return map
          }

          const activityEntry = map[activity.code] || {
            activity,
            photos: [] as Photo[],
          }
          activityEntry.photos.push(photo)

          return Object.assign(map, { [activity.code]: activityEntry })
        }, {} as { [key: string]: { activity: Activity; photos: Photo[] } })

        const photosByActivities = Object.values(photosByActivitiesMap)
        if (photosByActivities.length) {
          entries.push({
            date: batch.date,
            activities: photosByActivities,
          })
        }

        return entries
      }, [] as Array<{ date; activities }>)
  }

  // from near future to far future
  private readonly byActivityPlannedDueDate = (a, b) =>
    a.dates.planned.finish.ms - b.dates.planned.finish.ms

  // from near future to far future
  private readonly byActivityPlannedStartDate = (a, b) =>
    a.dates.planned.start.ms - b.dates.planned.start.ms

  // from now to past
  private readonly byActivityActualDueDate = (a, b) =>
    b.dates.actual.finish.ms - a.dates.actual.finish.ms

  private readonly byPhotoDescendingDate = (a, b) =>
    b.date.getTime() - a.date.getTime()

  private activitiesContainerMapper = (activity: Activity) => {
    const { selectedSorting, photosActivityIdMap } = this.props.photosStore
    const photos = photosActivityIdMap[activity.code]
    let rightSideContent = ''

    if (selectedSorting === PhotoSortingTypes.ACTIVITY) {
      rightSideContent = activity.location
    } else if (selectedSorting === PhotoSortingTypes.LOCATION) {
      rightSideContent = this.getActivityBlockHeader(activity)
    }

    return (
      <ActivityPhotosBlock
        key={activity.code}
        activity={activity}
        photos={photos}
        rightSideContent={rightSideContent}
      />
    )
  }

  private getActivityBlockHeader = activity => {
    if (!activity.didStart) {
      return 'Starts ' + this.getDateHeader(activity.dates.planned.start.date)
    }

    if (activity.didFinish) {
      return (
        'Completed ' + this.getDateHeader(activity.dates.planned.finish.date)
      )
    }

    return 'Due ' + this.getDateHeader(activity.dates.planned.finish.date)
  }

  private getActivitySection = sortedActivities => {
    const map = sortedActivities.reduce((acc, activity) => {
      const date = this.getActivityBlockHeader(activity)

      const activitiesByDate = acc[date] || []
      activitiesByDate.push(activity)
      return Object.assign(acc, { [date]: activitiesByDate })
    }, {} as { [key: string]: Activity[] })

    const mapEntries: any = Object.entries(map)
    return mapEntries.map(([date, activities]) => {
      const dateKey = date
      const dateHeader = date
      const titleClass = this.getTitleClass(dateHeader) + ' section-title'
      return (
        <div className="photo-gallery-container" key={dateKey}>
          <div className={titleClass}>{dateHeader}</div>
          {activities.map(this.activitiesContainerMapper)}
        </div>
      )
    })
  }

  private getViewByDate() {
    if (!this.photosByDateGroupedByActivity.length) {
      return this.renderNoPhotosMessage()
    }

    return this.photosByDateGroupedByActivity.map(batch => {
      const dateKey = batch.date.getTime()
      const dateHeader = this.getDateHeader(batch.date)
      const titleClass = this.getTitleClass(dateHeader) + ' section-title'
      return (
        <div className="photo-gallery-container" key={dateKey}>
          <div className={titleClass}>{dateHeader}</div>
          {batch.activities.map(({ activity, photos }) => {
            return (
              <ActivityPhotosBlock
                key={dateKey + activity.code}
                photos={photos}
                activity={activity}
                rightSideContent={activity.location}
              />
            )
          })}
        </div>
      )
    })
  }

  private getViewByActivity() {
    const currentActivitiesPhotosBlock = this.getActivitySection(
      this.activeActivitiesWithPhotos,
    )

    const completedActivitiesPhotosBlock = this.getActivitySection(
      this.completedActivitiesWithPhotos,
    )

    const futureActivitiesPhotosBlock = this.getActivitySection(
      this.futureActivitiesWithPhotos,
    )

    if (
      !currentActivitiesPhotosBlock.length &&
      !completedActivitiesPhotosBlock.length &&
      !futureActivitiesPhotosBlock.length
    ) {
      return this.renderNoPhotosMessage()
    }

    return (
      <div>
        <div>
          <div className="section-title">Current Activities</div>
          {currentActivitiesPhotosBlock}
        </div>
        <div>
          <div className="section-title">Completed Activities</div>
          {completedActivitiesPhotosBlock}
        </div>
        <div>
          <div className="section-title">Future Activities</div>
          {futureActivitiesPhotosBlock}
        </div>
      </div>
    )
  }

  private getViewByLocation(): JSX.Element {
    const locationsWithActivities = new Map<string, Activity[]>()

    const allActivities = this.activeActivitiesWithPhotos.concat(
      this.completedActivitiesWithPhotos,
      this.futureActivitiesWithPhotos,
    )

    allActivities.forEach(activity => {
      const activitiesByLocation = locationsWithActivities.get(
        activity.location,
      )

      if (!activitiesByLocation) {
        locationsWithActivities.set(activity.location, [activity])
      } else {
        activitiesByLocation.push(activity)
      }
    })

    const sortedLocationsWithActivities = Array.from(
      locationsWithActivities.entries(),
    ).sort()

    const locationBlocks = sortedLocationsWithActivities.map(location => {
      const locationName = location[0]
      const activities = location[1]

      const activitiesBlock = activities.map(this.activitiesContainerMapper)

      return (
        <div key={locationName}>
          <div className="section-title">{locationName}</div>
          {activitiesBlock}
        </div>
      )
    })

    if (!locationBlocks.length) {
      return this.renderNoPhotosMessage()
    }

    return <div>{locationBlocks}</div>
  }

  private renderNoPhotosMessage() {
    return (
      <div className="col x-center y-center pt30">
        <div className="row">
          <Icon
            icon="warning-sign"
            iconSize={20}
            className="warning-icon mr5"
          />
          {thereIsNoPhotoPassingFilters}
        </div>
      </div>
    )
  }
}
