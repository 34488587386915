import { action, observable } from 'mobx'

import IFilePreviewProperties from '~/client/src/shared/interfaces/IFilePreviewProperties'

export default class MobileFullscreenPreviewStore {
  @observable public files: IFilePreviewProperties[] = []
  @observable public fileIndex: number = null
  @observable public shouldShowFullscreenImagePreview: boolean = false

  @action.bound
  public showFullscreenPreview(
    files: IFilePreviewProperties[],
    fileIndex: number,
  ) {
    this.files = files
    this.fileIndex = fileIndex
    this.shouldShowFullscreenImagePreview = true
  }

  @action.bound
  public hideFullscreenPreview() {
    this.files = []
    this.fileIndex = null
    this.shouldShowFullscreenImagePreview = false
  }
}
