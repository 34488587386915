import * as React from 'react'

import { observer } from 'mobx-react'

import LogisticsContainer from '../Logistics/LogisticsContainer'

@observer
export default class CompactForms extends React.Component {
  public render() {
    return <LogisticsContainer isPermitOnly={true} />
  }
}
