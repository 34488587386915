import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { QRCode } from 'react-qrcode-logo'

import MobileInitialState from '~/client/src/mobile/stores/MobileInitialState'
import config from '~/client/src/shared/Config'
import * as Icons from '~/client/src/shared/components/Icons'
import LanguageSelector from '~/client/src/shared/components/LanguageSelector/LanguageSelector'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import {
  SCAN_CODE_SEPARATOR,
  ScanCodeTypes,
} from '~/client/src/shared/models/Scanner'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'
import { QR_CODE_LOGO_SRC } from '~/client/src/shared/utils/usefulStrings'

import MobileEventStore from '../../stores/EventStore/MobileEvents.store'
import MobileCommonStore from '../../stores/ui/MobileCommon.store'
import SidebarStore from './Sidebar.store'
import SidebarFooter from './components/SidebarFooter/SidebarFooter'
import SidebarHeader from './components/SidebarHeader/SidebarHeader'
import SidebarItems from './components/SidebarItems'
import SidebarLogoutItem from './components/SidebarLogoutItem'

import Colors from '~/client/src/shared/theme.module.scss'

import './Sidebar.scss'

const QR_CODE_TO_PRINT_SIZE = 200

interface ISidebar {
  common?: MobileCommonStore
  state?: MobileInitialState
  eventsStore?: MobileEventStore
}

@inject('common', 'state', 'eventsStore')
@observer
export default class Sidebar extends React.Component<ISidebar> {
  private readonly sidebarStore: SidebarStore

  public constructor(props: ISidebar) {
    super(props)

    this.sidebarStore = new SidebarStore(
      props.common,
      props.eventsStore,
      props.state,
    )
  }

  public render() {
    const { isSidebarOpened, sidebarItems, onClickHandler, logoutHandler } =
      this.sidebarStore

    const { isVisitorMode } = this.props.state

    return (
      <div
        className={classList({
          'sidebar scrollable': true,
          opened: isSidebarOpened,
          closed: !isSidebarOpened,
        })}
      >
        <SidebarHeader
          projectName={this.sidebarStore.activeProject.name}
          user={this.props.state.user}
          onClick={this.sidebarStore.onClickHandler}
        />
        {Localization.shouldShowLanguageSelector && (
          <LanguageSelector
            textSize="header"
            className="mx20 my10 primary-blue"
          />
        )}
        <div className="col">
          {this.renderQRCode()}
          <SidebarItems
            onClickHandler={onClickHandler}
            items={sidebarItems}
            isUserVisitor={isVisitorMode}
          />
          <SidebarLogoutItem onClick={logoutHandler} />
        </div>
        <SidebarFooter config={config} />
      </div>
    )
  }

  private renderQRCode() {
    return (
      <div className="qr-code-container col x-center y-center">
        <div className="col x-center">
          <QRCode
            value={this.userQRValue}
            logoImage={QR_CODE_LOGO_SRC}
            size={QR_CODE_TO_PRINT_SIZE}
            fgColor={ThemeMode.getHEXColor(Colors.neutral0)}
          />
          <div className="row x-center text extra-large">
            <Icons.User className="no-grow" />
            {this.props.state?.user?.fullName}
          </div>
        </div>
      </div>
    )
  }

  private get userQRValue(): string {
    return `${ScanCodeTypes.user}${SCAN_CODE_SEPARATOR}${this.props.state?.user?.id}`
  }
}
