import * as React from 'react'

import { action, computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import { FilterType } from '~/client/graph'
import MobileView from '~/client/src/mobile/components/MobileView'
import MobileEventStore from '~/client/src/mobile/stores/EventStore/MobileEvents.store'
import MobileInitialState from '~/client/src/mobile/stores/MobileInitialState'
import MobileActivityListStore from '~/client/src/mobile/stores/ui/ActivityList.store'
import MobileCommonStore from '~/client/src/mobile/stores/ui/MobileCommon.store'
import MobileActivityPresetsSelector from '~/client/src/mobile/views/ActivityList/components/ActvityFiltersPage/components/ActivityPresetSelector/MobileActivityPresetsSelector'
import * as Icons from '~/client/src/shared/components/Icons'
import { IMultiSelectOption } from '~/client/src/shared/components/MultiSelectFilter/MultiSelectFilter'
import ActivityCode from '~/client/src/shared/models/ActivityCode'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import ActivityPresetsStore from '~/client/src/shared/stores/domain/ActivityPresets.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import CustomActivityListFiltersStore from '~/client/src/shared/stores/domain/CustomActivityListFilters.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import {
  UNASSIGNED_FILTER_VALUE,
  zoneBaseName,
} from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import { NONE_COUNT_LABEL } from '~/client/src/shared/utils/usefulStrings'

import ActivityFiltersPageStore from './ActivityFiltersPage.store'
import ActivityFiltersMultiSelect from './components/ActivityFiltersMultiSelect'
import CustomFiltersSelector from './components/CustomFiltersSelector'
import MultiSelectItemLabel from './components/MultiSelectItemLabel'
import SaveFilterDialog from './components/SaveFilterDialog'

import './ActivityFiltersPage.scss'

interface IProps {
  common?: MobileCommonStore
  activityListStore?: MobileActivityListStore
  state?: MobileInitialState
  customActivityListFiltersStore?: CustomActivityListFiltersStore
  activityFiltersStore?: ActivityFiltersStore
  eventsStore?: MobileEventStore
  activitiesStore?: ActivitiesStore
  activityPresetsStore?: ActivityPresetsStore
  companiesStore?: CompaniesStore
  userProjectsStore?: UserProjectsStore
}

@inject(
  'common',
  'activityListStore',
  'state',
  'customActivityListFiltersStore',
  'activityFiltersStore',
  'eventsStore',
  'activitiesStore',
  'activityPresetsStore',
  'companiesStore',
  'userProjectsStore',
)
@observer
export default class ActivityFiltersPage extends React.Component<IProps> {
  private readonly store: ActivityFiltersPageStore

  public constructor(props: IProps) {
    super(props)

    this.store = new ActivityFiltersPageStore(
      props.activityListStore,
      props.customActivityListFiltersStore,
      props.activityFiltersStore,
      props.eventsStore,
      props.activitiesStore,
      props.activityPresetsStore,
      props.companiesStore,
      props.userProjectsStore,
    )
  }

  public componentDidMount() {
    this.props.common.hideNavBar()
  }

  public render() {
    return (
      <MobileView
        className="activity-filters-page"
        header={this.header}
        content={this.content}
        footer={this.footer}
        imagePreview={<SaveFilterDialog filtersPageStore={this.store} />}
      />
    )
  }

  private get header() {
    const { hideCustomFiltersPage } = this.store

    return (
      <div
        className="row py10 px20 bb-light-grey pointer"
        onClick={hideCustomFiltersPage}
      >
        <div className="col y-start no-grow pr10">
          <Icons.BackArrow />
        </div>
        <div className="text extra-large bold">Custom filters</div>
        <div onClick={this.resetFilters} className="no-grow reset-btn">
          Reset
        </div>
      </div>
    )
  }

  private resetFilters = event => {
    event.preventDefault()
    event.stopPropagation()

    setTimeout(() => {
      const { saveFilterValuesToState } = this.props.activityListStore

      this.store.resetFilterValues()
      this.store.resetActivityPreset()
      saveFilterValuesToState(null, null, null)
    }, 0)
  }

  private get content() {
    const { enabledFilterTypes } = this.props.activityListStore

    const {
      activityPresets,
      defaultCustomCompanyFilter,
      customFilters,
      selectedActivityPresetId,
      resetActivityPreset,
      codeFilterValuesByTypeMap,
    } = this.store

    const { filterInfoMap } = this.props.state.activityFiltersSettings
    const shouldShowActivityPresets = !!activityPresets.length
    const shouldShowCustomFilters = Boolean(
      defaultCustomCompanyFilter || customFilters.length,
    )

    return (
      <div className="pa20">
        {shouldShowActivityPresets && (
          <MobileActivityPresetsSelector
            onChange={this.selectActivityPreset}
            selectedPresetId={selectedActivityPresetId}
            presets={activityPresets}
            onReset={resetActivityPreset}
          />
        )}
        {shouldShowCustomFilters && (
          <CustomFiltersSelector filtersPageStore={this.store} />
        )}
        {enabledFilterTypes.map((filterType, idx) => {
          const title = filterInfoMap[filterType].getCaption()
          let options: IMultiSelectOption[]
          if (filterType === FilterType.Company) {
            options = this.companyFilterOptions
          } else {
            options = this.getActivityCodeTypeFilterOptions(filterType)
          }

          return (
            <ActivityFiltersMultiSelect
              options={options}
              values={codeFilterValuesByTypeMap.get(filterType) || []}
              title={title}
              filterType={filterType}
              onChange={this.changeActivityCodeFilterValues}
              key={idx}
            />
          )
        })}
      </div>
    )
  }

  private get footer() {
    const {
      applyCustomFiltersPage,
      onSaveFiltersClicked,
      activityCountBySelectedFilters,
    } = this.store
    return (
      <div className="row px20 py5 bt-light-grey">
        <button className="btn save-button mr20" onClick={onSaveFiltersClicked}>
          Save Filter
        </button>
        <button className="btn apply-button" onClick={applyCustomFiltersPage}>
          See {activityCountBySelectedFilters} Activities
        </button>
      </div>
    )
  }

  @action.bound
  private changeActivityCodeFilterValues(values: string[], filterType: string) {
    const { changeActivityCodeFilterValues } = this.store
    changeActivityCodeFilterValues(filterType, values)
  }

  @computed
  private get companyFilterOptions(): IMultiSelectOption[] {
    const options: IMultiSelectOption[] =
      this.props.companiesStore.availableSortedCompanies.map(company => ({
        label: (
          <MultiSelectItemLabel
            label={company.name}
            countLabel={this.getCompanyCountLabel(company.id)}
          />
        ),
        value: company.id,
      }))
    const unassignedOptionCountLabel = this.getCompanyCountLabel(
      UNASSIGNED_FILTER_VALUE,
    )
    options.push(
      this.getUnassignedMultiSelectOption(unassignedOptionCountLabel),
    )

    return options
  }

  private getActivityCodeTypeFilterOptions(type: string): IMultiSelectOption[] {
    const activityCodes = this.store.getCodesByFilterType(type)
    const options: IMultiSelectOption[] = activityCodes.map(code => ({
      label: (
        <MultiSelectItemLabel
          label={this.getActivityCodeLabel(code)}
          countLabel={this.getActivityCodeCountLabel(type, code.id)}
        />
      ),
      value: code.id,
    }))
    const unassignedOptionCountLabel = this.getActivityCodeCountLabel(
      type,
      UNASSIGNED_FILTER_VALUE,
    )
    options.push(
      this.getUnassignedMultiSelectOption(unassignedOptionCountLabel),
    )

    return options
  }

  private getActivityCodeLabel({ shortName, name }: ActivityCode): string {
    return shortName && shortName.includes(zoneBaseName) ? shortName : name
  }

  private getUnassignedMultiSelectOption(
    countLabel: string,
  ): IMultiSelectOption {
    return {
      label: (
        <MultiSelectItemLabel label="Unassigned" countLabel={countLabel} />
      ),
      value: UNASSIGNED_FILTER_VALUE,
    }
  }

  private getCompanyCountLabel(company: string) {
    const {
      getActivityCountByCompanyId,
      getActivityCountBySelectedFiltersAndCompanyId,
    } = this.store

    return this.getCountLabel(
      getActivityCountBySelectedFiltersAndCompanyId(company),
      getActivityCountByCompanyId(company),
    )
  }

  private getActivityCodeCountLabel(filterType: string, codeId: string) {
    const {
      getActivityCountBySelectedFiltersAndActivityCode,
      activityCountByActivityCode,
    } = this.store

    return this.getCountLabel(
      getActivityCountBySelectedFiltersAndActivityCode(filterType, codeId),
      activityCountByActivityCode[filterType][codeId] || 0,
    )
  }

  private getCountLabel(count: number, all: number) {
    return all ? `${count} of ${all}` : NONE_COUNT_LABEL
  }

  @action.bound
  private selectActivityPreset(event: any) {
    this.store.selectActivityPreset(event.target.value)
  }
}
