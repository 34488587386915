import React from 'react'

import { classList } from 'react-classlist-helper'

import './RoutesSlider.scss'

export enum SliderPosition {
  FROM_LEFT = 'FROM_LEFT',
  FROM_RIGHT = 'FROM_RIGHT',
}

const PUSH = 'PUSH'
const ANIMATE_PREPARE_DURATION = 500

interface IProps {
  action: string
  pathname: string
}

interface IState {
  animatePrepare: boolean
}

export default class RoutesSlider extends React.Component<IProps, IState> {
  public state: IState = {
    animatePrepare: false,
  }
  private postPrepareTimeout

  public componentDidMount() {
    this.startAnimation()
  }

  public UNSAFE_componentWillReceiveProps(newProps: IProps) {
    if (newProps.pathname !== this.props.pathname) {
      this.startAnimation()
    }
  }

  private startAnimation() {
    this.postPrepareTimeout = setTimeout(
      this.postPrepareAnimation,
      ANIMATE_PREPARE_DURATION,
    )
    this.setState({
      animatePrepare: true,
    })
  }

  private postPrepareAnimation = () => {
    clearTimeout(this.postPrepareTimeout)

    this.setState({ animatePrepare: false })
  }

  public render() {
    return (
      <div
        className={classList({
          'animatable full-height': true,
          'from-right': SliderPosition.FROM_RIGHT === this.animatePosition,
          'from-left': SliderPosition.FROM_LEFT === this.animatePosition,
          prepare: this.state.animatePrepare,
        })}
      >
        <div className="full-height">{this.props.children}</div>
      </div>
    )
  }

  private get animatePosition(): SliderPosition {
    return this.props.action === PUSH
      ? SliderPosition.FROM_RIGHT
      : SliderPosition.FROM_LEFT
  }
}
