import * as React from 'react'

import { observer } from 'mobx-react'

import { Logout } from '~/client/src/shared/components/Icons'

import {
  SidebarIconColumn,
  SidebarTitleColumn,
} from './SidebarSections/SidebarSections'

import '../../SectionIcon/SectionIcon.scss'

import Localization from '~/client/src/shared/localization/LocalizationManager'

interface ISidebarLogout {
  onClick: () => void
}

@observer
export default class SidebarLogoutItem extends React.Component<ISidebarLogout> {
  public render() {
    return (
      <div
        onClick={this.onClick}
        className="bg-white bb-cool-grey h48 row indication-click"
      >
        <SidebarIconColumn isDisabled={false}>
          <div className="section-icon-container">
            <Logout className="row text large logout ml2" />
          </div>
        </SidebarIconColumn>
        <SidebarTitleColumn
          text={Localization.translator.logOut}
          isDisabled={false}
          bold={false}
          isBadgeShown={false}
        />
      </div>
    )
  }

  private onClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.preventDefault()

    this.props.onClick()
  }
}
