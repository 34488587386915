import React from 'react'

import './GetTheAppBanner.scss'

import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { isAndroid } from 'react-device-detect'

import { CrossWhite } from '~/client/src/shared/components/Icons'
import { LocalStorageKey } from '~/client/src/shared/enums/LocalStorageKey'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { preventLastWordWrap } from '~/client/src/shared/utils/textUtils'

import { androidStoreURL, iosStoreURL } from '../../constants/storesInfo'

interface IProps {
  hidden?: boolean
  onDismiss?: () => void
}

const ANIMATION_DURATION = 1000

/**
 * A banner that prompts the user to download the mobile app.
 * Dev tip: hold Ctrl to dismiss it for good ;)
 */
@observer
export default class GetTheAppBanner extends React.Component<IProps> {
  @observable private shouldRender: boolean

  public constructor(props: IProps) {
    super(props)
    this.shouldRender = !props.hidden
  }

  private get downloadUrl(): string {
    return isAndroid ? androidStoreURL : iosStoreURL
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (this.props.hidden && !prevProps.hidden) {
      setTimeout(() => {
        this.shouldRender = false
      }, ANIMATION_DURATION)
    } else if (!this.props.hidden && prevProps.hidden) {
      this.shouldRender = true
    }
  }

  public render() {
    const i18n = Localization.translator
    const hiddenClass = this.props.hidden ? 'banner--hidden' : ''
    return this.shouldRender ? (
      <div className={`get-the-app-banner row ${hiddenClass}`}>
        <div className="close flex-shrink" onClick={this.handleDismiss}>
          <CrossWhite />
        </div>
        <div className="body-text text large white">
          {preventLastWordWrap(i18n.getMobileNativeApp_long)}
        </div>
        <div className="buttons">
          <a
            className="bg-color-blue-brand brada24 button large text white"
            href={this.downloadUrl}
            target="_blank"
          >
            {i18n.download}
          </a>
        </div>
      </div>
    ) : null
  }

  private handleDismiss = (event: React.MouseEvent<HTMLDivElement>) => {
    sessionStorage.setItem(LocalStorageKey.GetTheAppBannerDismissed, 'true')

    this.props.onDismiss()

    if (event.ctrlKey) {
      localStorage.setItem(LocalStorageKey.GetTheAppBannerDismissed, 'true')
    }
  }
}

export function shouldShowBanner(): boolean {
  return (
    // Not in the webview
    !window.ReactNativeWebView &&
    // One-time popup has already been dismissed
    !!localStorage.getItem(LocalStorageKey.OneTimePopup) &&
    // Banner hasn't been dismissed yet
    !isBannerDismissed()
  )
}

function isBannerDismissed(): boolean {
  return (
    !!localStorage.getItem(LocalStorageKey.GetTheAppBannerDismissed) ||
    !!sessionStorage.getItem(LocalStorageKey.GetTheAppBannerDismissed)
  )
}
