import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'

import MobileFileInput from '~/client/src/mobile/components/FileInput/MobileFileInput'
import MobileView from '~/client/src/mobile/components/MobileView'
import MobileCommonStore from '~/client/src/mobile/stores/ui/MobileCommon.store'
import CategoryOfVarianceThread from '~/client/src/shared/components/CategoryOfVarianceEntry/CategoryOfVarianceThread'
import CategoryOfVarianceIcon from '~/client/src/shared/components/CategoryOfVarianceIcon'
import ContentObjectViewBase from '~/client/src/shared/components/ContentObject/ContentObjectViewBase'
import DaySeparator from '~/client/src/shared/components/DaySeparator'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import RootStore from '~/client/src/shared/stores/Root.store'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import CategoriesOfVarianceStore from '~/client/src/shared/stores/domain/CategoriesOfVariance.store'
import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'

interface IProps {
  contentObjectsStore: CategoriesOfVarianceStore
  commonStore: MobileCommonStore
  activitiesStore: ActivitiesStore
  slackBarStore: SlackBarStore
}

interface IRouteParams {
  covId: string
}

function mapToProps(store: RootStore) {
  return {
    activitiesStore: store.activitiesStore,
    commonStore: store.common,
    contentObjectsStore: store.categoriesOfVarianceStore,
    slackBarStore: store.slackBarStore,
  }
}

@inject(mapToProps)
@observer
class CategoryOfVarianceView extends ContentObjectViewBase<
  IProps & RouteComponentProps<IRouteParams>
> {
  protected className: string = 'category-of-variance'

  public componentDidMount() {
    this.props.commonStore.hideNavBar()
    this.contentObjectId = this.props.match.params.covId
  }

  public render() {
    return (
      <MobileView
        content={this.content}
        footer={this.footer}
        header={this.header}
        imagePreview={this.imagePreview}
      />
    )
  }

  public get content() {
    const currEntryDate = new Date(this.contentObject.createdAt)

    return (
      <div className="activity-log">
        <DaySeparator date={currEntryDate} />
        <CategoryOfVarianceThread
          categoryOfVariance={this.contentObject}
          hideButtons={true}
          FileInputType={MobileFileInput}
        />
      </div>
    )
  }

  @action.bound
  public goBack() {
    const store = this.props.slackBarStore
    if (store.shouldShowPreview) {
      return store.hidePreview()
    }

    const { commonStore } = this.props
    commonStore.toggleNavBar()
    commonStore.history.goBack()
  }

  @action.bound
  public goToActivityDetails() {
    this.props.commonStore.displayActivityDetailsView(this.activity.code)
  }

  protected caption = () => Localization.translator.categoryOfVariance
  protected replyToCaption = () =>
    Localization.translator.replyToCategoryOfVariance

  protected getIcon(): React.ReactNode {
    return <CategoryOfVarianceIcon categoryOfVariance={this.contentObject} />
  }
}

export default withRouter(CategoryOfVarianceView)
