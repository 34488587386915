import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import CalendarEvent from '~/client/src/shared/models/CalendarEvent'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import CalendarDayViewStore from '../../CalendarDayView.store'

import './MultiDayEventsBar.scss'

interface IProps {
  viewingDate: Date
  setViewingDate: (date: Date) => void
  events: CalendarEvent[]
  calendarStore: CalendarDayViewStore

  projectDateStore?: ProjectDateStore
}

@inject('projectDateStore')
@observer
export default class MultiDayEventsBar extends React.Component<IProps> {
  public render() {
    const {
      viewingDate,
      setViewingDate,
      calendarStore,
      projectDateStore: {
        isSameDay,
        isWithinDateInterval,
        getWeekdayToDisplay,
      },
    } = this.props

    return (
      <div className="multi-day-events-container row y-start bg-white pt5">
        {this.weekDays.map((date, idx) => {
          const isActiveDate = isSameDay(viewingDate, date)
          const events = calendarStore.getActiveMultiDayEvents(
            this.props.events,
          )

          const className = classList({
            'text small center': true,
            'orange bold': isActiveDate,
          })

          return (
            <div
              className="x-axis-column-width col y-end"
              key={idx}
              onClick={setViewingDate.bind(null, date)}
            >
              <div className={className}>{date.getDate()}</div>
              <div className={`${className} mb4`}>
                {getWeekdayToDisplay(date)}
              </div>
              {events?.length > 0 && (
                <div className="col">
                  {events.map((event, idx) => {
                    const isActiveEvent = isWithinDateInterval(
                      event.startDate,
                      event.endDate,
                      date,
                    )
                    const isStartDate = isSameDay(event.startDate, date)
                    const isEndDate = isSameDay(event.endDate, date)

                    return (
                      <div
                        key={event.dataId || idx}
                        className={classList({
                          'multi-day-event mb2 text white': true,
                          'empty-event': !isActiveEvent,
                          'brls4 pl5': isStartDate,
                          'brrs4 pr5': isEndDate,
                        })}
                      >
                        {isStartDate && event.label}
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )
        })}
      </div>
    )
  }

  public get weekDays(): Date[] {
    const {
      viewingDate,
      projectDateStore: { startOfWeek, endOfWeek, getDaysInInterval },
    } = this.props

    return getDaysInInterval(startOfWeek(viewingDate), endOfWeek(viewingDate))
  }
}
