import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'

import QrCodesStore from '~/client/src/shared/components/QRCodes/QRCodes.store'
import QRCodesContent from '~/client/src/shared/components/QRCodes/components/QRCodesContent'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ScannersStore from '~/client/src/shared/stores/domain/Scanners.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import BottomNavigationNavBar from '../../components/BottomNavigationNavBar/BottomNavigationNavBar'
import MobileView from '../../components/MobileView'
import MobileEventStore from '../../stores/EventStore/MobileEvents.store'
import MobileCommonStore from '../../stores/ui/MobileCommon.store'

import './QRCodes.scss'

interface IProps {
  eventsStore?: MobileEventStore
  common?: MobileCommonStore
  scannersStore?: ScannersStore
  projectMembersStore?: ProjectMembersStore
  userProjectsStore?: UserProjectsStore
}

@inject(
  'common',
  'scannersStore',
  'eventsStore',
  'projectMembersStore',
  'userProjectsStore',
)
@observer
class QRCodes extends React.Component<IProps & RouteComponentProps> {
  private qrCodesStore: QrCodesStore = null

  public constructor(props: IProps & RouteComponentProps) {
    super(props)

    this.qrCodesStore = new QrCodesStore(
      props.eventsStore,
      props.scannersStore,
      props.projectMembersStore,
      props.userProjectsStore,
    )
  }

  public componentDidMount(): void {
    this.props.common.hideNavBar()
  }

  public render(): JSX.Element {
    const { isFullScreenMode, className } = this.qrCodesStore
    const containerClassName = `col layout-content qr-codes ${className}`

    return (
      <MobileView
        content={
          <QRCodesContent
            store={this.qrCodesStore}
            isFullscreenAllowed={true}
          />
        }
        footer={!isFullScreenMode && <BottomNavigationNavBar />}
        className={containerClassName}
      />
    )
  }
}

export default withRouter(QRCodes)
