import * as React from 'react'

import { inject, observer } from 'mobx-react'

import BottomNavigationNavBar from '~/client/src/mobile/components/BottomNavigationNavBar/BottomNavigationNavBar'
import PhotoGallery from '~/client/src/mobile/views/Documents/components/PhotoGallery/PhotoGallery'
import PhotoSorter from '~/client/src/mobile/views/Documents/components/PhotoSorter/PhotoSorter'
import { Content, Footer, View } from '~/client/src/shared/components/Layout'
import { Loader } from '~/client/src/shared/components/Loader'

import MobileInitialState from '../../stores/MobileInitialState'
import MobileCommonStore from '../../stores/ui/MobileCommon.store'

const SPINNER_SIZE = 50

interface IDocumentsViewProps {
  common?: MobileCommonStore
  state?: MobileInitialState
}

@inject('common', 'state')
@observer
export default class Documents extends React.Component<IDocumentsViewProps> {
  public componentDidMount() {
    this.props.common.showNavBar()
  }

  public render() {
    if (this.props.state.isLoading) {
      return this.renderLoader()
    }

    return (
      <View>
        <Content>
          <PhotoSorter />
          <PhotoGallery />
        </Content>
        <Footer>
          <BottomNavigationNavBar />
        </Footer>
      </View>
    )
  }

  private renderLoader() {
    return (
      <div className="full-height">
        <Loader size={SPINNER_SIZE} />
      </div>
    )
  }
}
