import * as React from 'react'

import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'

import Text from '~/client/src/shared/components/Typography/Text'
import Flag from '~/client/src/shared/models/Flag'
import Rfi from '~/client/src/shared/models/Rfi'
import ScheduleComment from '~/client/src/shared/models/ScheduleComment'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import FlagsStore from '~/client/src/shared/stores/domain/Flags.store'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import RfisStore from '~/client/src/shared/stores/domain/Rfis.store'
import ScheduleCommentsStore from '~/client/src/shared/stores/domain/ScheduleComments.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import {
  isFlagType,
  isRFIType,
  isScheduleCommentType,
  isStatusUpdateType,
} from '~/client/src/shared/types/NotificationTypes'

import BaseNotification from '../models/Notification'

interface IProps {
  notification: BaseNotification
  flagsStore?: FlagsStore
  rfisStore?: RfisStore
  statusUpdatesStore?: StatusUpdatesStore
  messagesStore?: MessagesStore
  scheduleCommentsStore?: ScheduleCommentsStore
}

@inject(
  'flagsStore',
  'rfisStore',
  'statusUpdatesStore',
  'messagesStore',
  'scheduleCommentsStore',
)
@observer
export default class NotificationMessage extends React.Component<IProps> {
  @computed
  private get message() {
    const {
      flagsStore,
      rfisStore,
      statusUpdatesStore,
      messagesStore,
      notification,
      scheduleCommentsStore,
    } = this.props
    const messages = messagesStore.list
    const { type, entityId } = notification
    switch (true) {
      case isFlagType(type): {
        const flag = flagsStore.byId.get(entityId) || Flag.none
        const message = messages
          .filter(m => m.threadId === flag.threadId)
          .sort((a, b) => a.updatedAt - b.updatedAt)[0]
        return message && message.text
      }

      case isRFIType(type): {
        const rfi = rfisStore.byId.get(entityId) || Rfi.none
        const message = messages
          .filter(m => m.threadId === rfi.threadId)
          .sort((a, b) => a.updatedAt - b.updatedAt)[0]
        return message && message.text
      }

      case isScheduleCommentType(type): {
        const sc =
          scheduleCommentsStore.byId.get(entityId) || ScheduleComment.none
        const message = messages
          .filter(m => m.threadId === sc.threadId)
          .sort((a, b) => a.updatedAt - b.updatedAt)[0]
        return message && message.text
      }

      case isStatusUpdateType(type):
        const statusUpdate =
          statusUpdatesStore.byId.get(entityId) || StatusUpdate.none
        return (
          statusUpdate &&
          `${statusUpdate.percentComplete}% complete, Manpower: ${statusUpdate.manpower}`
        )

      default:
        return null
    }
  }

  public render() {
    return (
      <Text bold={false} className="light large">
        {this.message}
      </Text>
    )
  }
}
