import React from 'react'

import { inject, observer } from 'mobx-react'

import LogisticsSitemap from '~/client/src/shared/components/LogisticsSitemap/LogisticsSitemap'
import LogisticsSitemapSetUpStore from '~/client/src/shared/components/LogisticsSitemap/LogisticsSitemapSetUp.store'
import SitemapsGallery from '~/client/src/shared/components/SitemapsGallery/SitemapsGallery'
import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import Announcement from '~/client/src/shared/models/Announcement'
import Delivery from '~/client/src/shared/models/Delivery'
import SitePermit from '~/client/src/shared/models/Permit'
import { SAVE_LOGISTICS_CONFIGURATIONS } from '~/client/src/shared/stores/EventStore/eventConstants'
import BasemapsStore from '~/client/src/shared/stores/domain/Basemaps.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import SitemapItemsStore from '~/client/src/shared/stores/domain/SitemapItems.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import MobileFileInput from '../../../components/FileInput/MobileFileInput'
import MobileInitialState from '../../../stores/MobileInitialState'
import MobileLogisticsStore from '../stores/MobileLogistics.store'
import MobileLogisticsMapFilterStore from '../stores/MobileLogisticsFilters/MobileLogisticsMapFilter.store'
import MobileLogisticsMapStore from '../stores/MobileLogisticsMap.store'
import CompactLogisticsFilter from './CompactLogsiticsFilter/CompactLogisticsFilter'
import LogisticsFilters from './LogisticsFilters/LogisticsFilters'

export interface IProps {
  logisticsSitemapsIds: string[]
  logisticsStore: MobileLogisticsStore
  logisticsMapStore: MobileLogisticsMapStore
  showAnnouncement: (announcement: Announcement) => void
  openPermitViewForm: (permit: SitePermit) => void
  openDeliveryViewForm: (delivery: Delivery) => void

  sitemapsStore?: SitemapsStore
  basemapsStore?: BasemapsStore
  sitemapItemsStore?: SitemapItemsStore
  locationAttributesStore?: LocationAttributesStore
  logisticsMapFilterStore: MobileLogisticsMapFilterStore
  companiesStore?: CompaniesStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
  tagsStore?: TagsStore
  state?: MobileInitialState

  isPermitOnly?: boolean
}
@inject(
  'sitemapsStore',
  'basemapsStore',
  'sitemapItemsStore',
  'locationAttributesStore',
  'tagsStore',
  'state',
  'companiesStore',
  'userProjectsStore',
  'projectMembersStore',
)
@observer
export default class MobileLogisticsMap extends React.Component<IProps> {
  public readonly store: LogisticsSitemapSetUpStore = null
  private textboxesCache: ICanvasImageCache = {}

  public constructor(props: IProps) {
    super(props)

    this.store = new LogisticsSitemapSetUpStore(
      props.sitemapsStore,
      props.basemapsStore,
      props.sitemapItemsStore,
      props.locationAttributesStore,
      props.tagsStore,
      props.state,
    )
  }

  public componentWillUnmount() {
    this.textboxesCache = {}
  }

  public render() {
    const { logisticsSitemapsIds, isPermitOnly } = this.props
    const { toggleFilters, isFiltersModalOpen } = this.props.logisticsMapStore

    return (
      <>
        <div className="map-filters body-header row full-width absolute z-index-100">
          <LogisticsFilters
            companiesStore={this.props.companiesStore}
            logisticsStore={this.props.logisticsStore}
            logisticsContentStore={this.props.logisticsMapStore}
            logisticsFilterStore={this.props.logisticsMapFilterStore}
            isPermitOnly={this.props.isPermitOnly}
            state={this.props.state}
            userProjectsStore={this.props.userProjectsStore}
            selectedOptionsCount={this.selectedOptionsCount}
            isMapFilters={true}
          />
        </div>
        <SitemapsGallery
          isHideButtonDisplayed={false}
          sitemapsIds={logisticsSitemapsIds}
          SelectedSitemapComponent={this.SitemapComponent}
          eventName={SAVE_LOGISTICS_CONFIGURATIONS}
          shouldDisableSwap={true}
          shouldUseFullHeight={false}
          shouldDisableZoom={true}
          shouldIncreasedIcons={true}
          FileInputType={MobileFileInput}
          areArrowsLeftSided={true}
          isCompactMode={true}
          mapboxEditorStore={this.store.mapBoxEditorStore}
          isCreationLimited={isPermitOnly}
          shouldShowCreateNewButton={false}
          hasFiltersBar={true}
        />
        <CompactLogisticsFilter
          store={this.props.logisticsMapFilterStore}
          onClose={toggleFilters}
          isShown={isFiltersModalOpen}
          selectedOptionsCount={this.selectedOptionsCount}
          companiesStore={this.props.companiesStore}
          projectMembersStore={this.props.projectMembersStore}
          tagsStore={this.props.tagsStore}
          isMapFilters={true}
        />
      </>
    )
  }

  private get SitemapComponent() {
    const {
      openPermitViewForm,
      showAnnouncement,
      openDeliveryViewForm,
      logisticsStore,
      isPermitOnly,
      logisticsMapStore,
    } = this.props

    return props => (
      <div className="sitemap-container full-width full-height">
        <LogisticsSitemap
          {...props}
          logisticsMapStore={logisticsMapStore}
          store={this.store}
          logisticsStore={logisticsStore}
          textboxesCache={this.textboxesCache}
          areDeliveriesHidden={isPermitOnly}
          shouldUseFullHeight={true}
          openPermit={openPermitViewForm}
          onPinClick={!isPermitOnly && showAnnouncement}
          openSiteDelivery={openDeliveryViewForm}
          openAnnouncement={showAnnouncement}
          shouldRenderSwipeableCards={true}
          mapBoxEditorStore={this.store.mapBoxEditorStore}
          isPermitOnly={isPermitOnly}
        />
      </div>
    )
  }

  private get selectedOptionsCount(): number {
    return Object.values(
      this.props.logisticsMapFilterStore.filterStoresByTypeMap,
    )
      .map(value => value.selectedOptions.size)
      .reduce((a, c) => a + c)
  }
}
