import React from 'react'

import { classList } from 'react-classlist-helper'

import './LogisticsFilter.scss'

interface IProps {
  icon: JSX.Element
  onClick: () => void
  isSelected: boolean
  text?: string
  isHidden?: boolean
}

export default class LogisticsFilter extends React.Component<IProps> {
  public render() {
    const { icon, onClick, text, isSelected, isHidden } = this.props
    if (isHidden) {
      return null
    }

    return (
      <div
        className={classList({
          'no-grow row brada5 mx4 logistics-filter-button no-width': true,
          'selected ba-palette-brand-primary primary-blue bg-blue-light':
            isSelected,
          'ba-palette-brand-lighter bg-white': !isSelected,
        })}
        onClick={onClick}
      >
        {!!icon && <div className="mx8">{icon}</div>}
        {!!text && (
          <div
            className={classList({
              'text large no-white-space-wrap mr12': true,
              ml12: !icon,
            })}
          >
            {text}
          </div>
        )}
      </div>
    )
  }
}
