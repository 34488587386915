enum SectionNames {
  NOTIFICATIONS = 'notifications',
  ACTIVITIES = 'activities',
  HOME = 'home',
  FORMS = 'forms',
  DELIVERIES = 'deliveries',
  DOCUMENTS = 'documents',
  MESSAGES = 'messages',
  PROJECTS = 'projects',
  LOGIN = 'login',
  RESET_PASSWORD = 'resetPassword',
  INFO = 'info',
  USERS = 'users',
  PROFILE = 'profile',
  QR_CODES = 'qr-codes',
  QR_CODE = 'qr-code',
  ADD_TEAMMATES = 'addTeammates',
  NOTIFICATION_SETTINGS = 'notification-settings',

  ONE_TIME_POPUP = 'oneTimePopup',
}

export default SectionNames
