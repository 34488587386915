import * as React from 'react'

import { observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'

import MobileView from '~/client/src/mobile/components/MobileView'
import PasswordResetView from '~/client/src/shared/components/PasswordResetView/PasswordResetView'

interface IRouteParams {
  resetCode?: string
}

@observer
class PasswordResetViewBaseMobile extends React.Component<
  RouteComponentProps<IRouteParams>
> {
  public render() {
    return <MobileView content={this.content} />
  }

  private get content() {
    const { location, match } = this.props

    return (
      <PasswordResetView
        resetCode={match.params.resetCode}
        email={location.state?.email}
      />
    )
  }
}

export default withRouter(PasswordResetViewBaseMobile)
