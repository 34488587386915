import * as React from 'react'

import { inject, observer } from 'mobx-react'

import Timestamp from '~/client/src/shared/components/Timestamp'

import ProjectDateStore from '../stores/ui/ProjectDate.store'

export interface IProps {
  time: number
  projectDateStore?: ProjectDateStore
}

@inject('projectDateStore')
@observer
export default class NotificationTimestampComponent extends React.Component<IProps> {
  private get oldTimeStamp() {
    const { time, projectDateStore } = this.props
    return projectDateStore.getMonthAndDayToDisplay(time)
  }

  private get isToday() {
    const { time, projectDateStore } = this.props
    return projectDateStore.isToday(time)
  }

  public render() {
    const { time } = this.props
    return (
      <div className="text right">
        {this.isToday ? <Timestamp time={time} /> : this.oldTimeStamp}
      </div>
    )
  }
}
