import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'

import MobileCommonStore from '~/client/src/mobile/stores/ui/MobileCommon.store'

import MobileInitialState from '../stores/MobileInitialState'

export interface IProps {
  state?: MobileInitialState
  common?: MobileCommonStore
}

@inject('state', 'common')
@observer
class NotificationCountLabel extends React.Component<
  IProps & RouteComponentProps<any>
> {
  public render() {
    const { unreadNotificationsCount } = this.props.state
    if (!unreadNotificationsCount) {
      return null
    }

    return (
      <div className="col no-grow indication-click">
        <div
          className="br-rounded bg-red text white h15 w20 row x-center y-center"
          onClick={this.navigateToNotifications}
        >
          {unreadNotificationsCount}
        </div>
      </div>
    )
  }

  @action.bound
  private navigateToNotifications(event) {
    event.stopPropagation()
    this.props.common.displayNotifications()
  }
}

export default withRouter(NotificationCountLabel)
