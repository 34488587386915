import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import PhotoSortingTypes from '~/client/src/shared/enums/PhotoSortingTypes'
import PhotosStore from '~/client/src/shared/stores/domain/Photos.store'

interface IProps {
  photosStore?: PhotosStore
  sortingType: PhotoSortingTypes
}

@inject('photosStore')
@observer
export default class SortingTypeItem extends React.Component<IProps> {
  public render() {
    const {
      sortingType,
      photosStore: { selectedSorting },
    } = this.props

    return (
      <div
        className={classList({
          'text large capitalize inline-block py8 px15': true,
          'bb2-primary-blue bold': selectedSorting === sortingType,
        })}
        onClick={this.onClick}
      >
        by {sortingType}
      </div>
    )
  }

  private onClick = () => {
    const {
      sortingType,
      photosStore: { changeSelectedSorting },
    } = this.props

    changeSelectedSorting(sortingType)
  }
}
