import { action, computed, observable } from 'mobx'

import { FilterType } from '~/client/graph'
import MobileEventStore from '~/client/src/mobile/stores/EventStore/MobileEvents.store'
import MobileActivityListStore from '~/client/src/mobile/stores/ui/ActivityList.store'
import { CustomFilterDialogModes } from '~/client/src/shared/enums/CustomFilterDialogModes'
import Activity from '~/client/src/shared/models/Activity'
import ActivityPreset from '~/client/src/shared/models/ActivityPreset'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityFiltersStore from '~/client/src/shared/stores/domain/ActivityFilters.store'
import ActivityPresetsStore from '~/client/src/shared/stores/domain/ActivityPresets.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import CustomActivityListFiltersStore from '~/client/src/shared/stores/domain/CustomActivityListFilters.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import CustomActivityListFilter, {
  ActivityFilterMap,
} from '~/client/src/shared/types/CustomActivityListFilter'
import { UNASSIGNED_FILTER_VALUE } from '~/client/src/shared/utils/ZoneLevelLocationConstants'

export default class ActivityFiltersPageStore {
  @observable public isSaveFilterDialogShown: boolean = false
  @observable public saveFilterDialogInstance: CustomActivityListFilter = null
  @observable public editableFilterName: string = ''
  @observable public editableFilterIsPublic: boolean = false
  @observable public filterDialogMode = CustomFilterDialogModes.Save
  @observable public codeFilterValuesByTypeMap: Map<string, string[]> =
    new Map()
  @observable public selectedActivityPresetId: string = null

  public constructor(
    private readonly activityListStore: MobileActivityListStore,
    private readonly customFiltersStore: CustomActivityListFiltersStore,
    private readonly activityFiltersStore: ActivityFiltersStore,
    private readonly eventsStore: MobileEventStore,
    private readonly activitiesStore: ActivitiesStore,
    private readonly activityPresetsStore: ActivityPresetsStore,
    private readonly companiesStore: CompaniesStore,
    private readonly userProjectsStore: UserProjectsStore,
  ) {
    this.setFilterValuesFromState()
  }

  @computed
  public get isCustomFilterEditingDisabled() {
    return !this.selectedCustomFilter || this.isDefaultCompanyFilterSelected
  }

  public get selectedCustomFilterId() {
    return this.eventsStore.appState.filters.selectedCustomFilterId
  }

  public set selectedCustomFilterId(id: string) {
    this.eventsStore.appState.filters.selectedCustomFilterId = id
  }

  public get customFilters(): CustomActivityListFilter[] {
    return this.customFiltersStore.list
  }

  public get activityPresets(): ActivityPreset[] {
    return this.activityPresetsStore.list
  }

  public get selectedCustomFilter(): CustomActivityListFilter {
    if (
      this.defaultCustomCompanyFilter &&
      this.selectedCustomFilterId ===
        CustomActivityListFilter.defaultCustomCompanyFilterId
    ) {
      return this.defaultCustomCompanyFilter
    }

    return this.customFiltersStore.byId.get(this.selectedCustomFilterId)
  }

  @computed
  public get defaultCustomCompanyFilter(): CustomActivityListFilter {
    const { userActiveProjectSettings, activeProject, user } =
      this.eventsStore.appState

    const companyName = this.companiesStore.getCompanyNameById(
      userActiveProjectSettings?.companyId,
      '',
    )

    if (
      !companyName ||
      !this.companiesStore.availableSortedCompanies
        .map(({ name }) => name)
        .includes(companyName)
    ) {
      return null
    }

    const filters = this.activityFiltersStore.activityCodeFilterTypes.reduce(
      (map, filterType) => {
        const codeIds = this.getCodesByFilterType(filterType)
          .map(({ id }) => id)
          .concat([UNASSIGNED_FILTER_VALUE])

        map[filterType] = codeIds

        return map
      },
      { [FilterType.Company]: [companyName] },
    )

    return new CustomActivityListFilter(
      CustomActivityListFilter.defaultCustomCompanyFilterId,
      companyName,
      this.editableFilterIsPublic,
      activeProject.id,
      user.id,
      filters,
    )
  }

  private get isDefaultCompanyFilterSelected() {
    if (!this.selectedCustomFilter) {
      return false
    }

    return (
      this.selectedCustomFilter.id ===
      CustomActivityListFilter.defaultCustomCompanyFilterId
    )
  }

  @computed
  public get selectedPreset(): ActivityPreset {
    return this.activityPresetsStore.getById(this.selectedActivityPresetId)
  }

  @computed
  private get codeFilterValuesByType(): ActivityFilterMap {
    const codesByType = {}
    this.codeFilterValuesByTypeMap.forEach(
      (codeIds, typeId) => (codesByType[typeId] = codeIds),
    )

    return codesByType
  }

  @computed
  public get activityCountBySelectedFilters(): number {
    if (this.selectedPreset) {
      return this.selectedPresetActivities.length
    }

    if (this.selectedCustomFilter) {
      return this.activityListStore.getActivitiesByCustomFilter(
        this.selectedCustomFilter,
      ).length
    }

    return this.activityListStore.todayActivities.filter(a =>
      this.activityListStore.isActivityInSelectedFilters(
        a,
        this.codeFilterValuesByType,
      ),
    ).length
  }

  @computed
  public get selectedPresetActivities(): Activity[] {
    if (!this.selectedPreset) {
      return []
    }

    const presetActivityCodes = Object.values(
      this.selectedPreset.activities || {},
    )

    return presetActivityCodes
      .map(id => this.activitiesStore.byId.get(id))
      .filter(a => a)
  }

  @computed
  public get activityCountByActivityCode() {
    const map = {}
    const filterTypesByCodeIdMap = {}
    const { enabledFilterTypes } = this.activityListStore
    enabledFilterTypes.map(filterType => {
      map[filterType] = {
        [UNASSIGNED_FILTER_VALUE]: 0,
      }
      this.getCodesByFilterType(filterType).forEach(({ id }) => {
        if (!filterTypesByCodeIdMap[id]) {
          filterTypesByCodeIdMap[id] = []
        }
        filterTypesByCodeIdMap[id].push(filterType)
      })
    })

    this.activityListStore.todayActivities.forEach(activity => {
      if (!activity.codeIds.length) {
        enabledFilterTypes.forEach(filterType => {
          map[filterType][UNASSIGNED_FILTER_VALUE]++
        })
      } else {
        // filters where activity is unassigned
        const otherFilterTypes = enabledFilterTypes.slice()

        activity.codeIds.forEach(codeId => {
          // eslint-disable-next-line @typescript-eslint/no-extra-semi
          ;(filterTypesByCodeIdMap[codeId] || []).forEach(filterType => {
            if (!map[filterType][codeId]) {
              map[filterType][codeId] = 0
            }

            map[filterType][codeId]++

            const index = otherFilterTypes.indexOf(filterType)
            if (index > -1) {
              otherFilterTypes.splice(index, 1)
            }
          })
        })

        otherFilterTypes.forEach(filterType => {
          map[filterType][UNASSIGNED_FILTER_VALUE]++
        })
      }
    })

    return map
  }

  @computed
  public get activityCountByCompany() {
    const map = {}
    const increment = (company: string) => {
      if (!map[company]) {
        map[company] = 0
      }
      map[company]++
    }

    this.activityListStore.todayActivities.forEach(activity => {
      if (!activity.companies.length) {
        increment(UNASSIGNED_FILTER_VALUE)
      } else {
        activity.companies.forEach(company => {
          increment(company)
        })
      }
    })

    return map
  }

  public getCodesByFilterType = (filterType: string) => {
    return this.activityFiltersStore.getCodesByFilterType(filterType)
  }

  @action.bound
  public selectActivityPreset(id: string) {
    this.selectedActivityPresetId = id
    this.resetFilterValues()
  }

  @action.bound
  public resetFilterValues() {
    this.selectedCustomFilterId = null
    this.codeFilterValuesByTypeMap.clear()

    this.activityFiltersStore.enabledFilterTypes.forEach(filterType => {
      if (filterType === FilterType.Company) {
        this.codeFilterValuesByTypeMap.set(
          filterType,
          this.companiesStore.availableSortedCompanies
            .map(({ id }) => id)
            .concat(UNASSIGNED_FILTER_VALUE),
        )
      } else {
        const activityCodes = this.getCodesByFilterType(filterType)
        const activityCodeIds = activityCodes.map(code => code.id)
        activityCodeIds.push(UNASSIGNED_FILTER_VALUE)
        this.codeFilterValuesByTypeMap.set(filterType, activityCodeIds)
      }
    })
  }

  public getActivityCountByCompanyId = (companyId: string) => {
    return (
      this.activityFiltersStore.activitiesCodesByCompanyMap[companyId] || []
    ).length
  }

  @computed
  private get activitiesBySelectedFiltersExcludingFilterTypes() {
    const map = {}
    this.activityListStore.enabledFilterTypes.forEach(filterType => {
      map[filterType] =
        this.getActivitiesBySelectedFiltersExcludingFilterType(filterType)
    })
    return map
  }

  public getActivityCountBySelectedFiltersAndCompanyId = (
    companyId: string,
  ) => {
    const activities =
      this.activitiesBySelectedFiltersExcludingFilterTypes[FilterType.Company]
    return activities.filter(a =>
      this.activityListStore.isActivityInCompanyFilter(a, [companyId]),
    ).length
  }

  public getActivityCountBySelectedFiltersAndActivityCode = (
    filterType: string,
    codeId: string,
  ) => {
    const activities =
      this.activitiesBySelectedFiltersExcludingFilterTypes[filterType]
    return activities.filter(a =>
      this.activityListStore.isActivityInCodeFilter(a, filterType, [codeId]),
    ).length
  }

  @action.bound
  public resetActivityPreset() {
    this.selectedActivityPresetId = null
  }

  @action.bound
  @action
  public changeActivityCodeFilterValues(filterType: string, values: string[]) {
    this.codeFilterValuesByTypeMap.set(filterType, values)
    this.eventsStore.appState.filters.selectedCustomFilterId = null
    this.resetActivityPreset()
  }

  @action.bound
  public onSaveFiltersClicked() {
    this.saveFilterDialogInstance = this.customFiltersStore.createFromValues(
      this.codeFilterValuesByType,
      this.editableFilterIsPublic,
    )
    this.editableFilterName = ''
    this.filterDialogMode = CustomFilterDialogModes.Save
    this.isSaveFilterDialogShown = true
  }

  @action.bound
  public hideCustomFiltersPage() {
    this.eventsStore.appState.filters.isFilterPageShown = false
  }

  @action.bound
  public applyCustomFiltersPage() {
    this.activityListStore.saveFilterValuesToState(
      this.codeFilterValuesByType,
      this.selectedActivityPresetId,
      this.selectedCustomFilterId,
    )
    this.hideCustomFiltersPage()
  }

  private getActivitiesBySelectedFiltersExcludingFilterType = (
    filterType: string,
  ) => {
    const filter = Object.assign({}, this.codeFilterValuesByType)

    delete filter[filterType]

    return this.activityListStore.todayActivities.filter(a =>
      this.activityListStore.isActivityInSelectedFilters(a, filter),
    )
  }

  @action.bound
  private setFilterValuesFromState() {
    this.codeFilterValuesByTypeMap.clear()
    const { mobileActivityFiltersByFilterType, appliedActivityPresetId } =
      this.eventsStore.appState
    Object.keys(mobileActivityFiltersByFilterType).forEach(filterType => {
      const values = mobileActivityFiltersByFilterType[filterType]
      this.codeFilterValuesByTypeMap.set(filterType, values)
    })

    this.selectedActivityPresetId = appliedActivityPresetId
  }
}
