import * as React from 'react'

import { Spinner } from '@blueprintjs/core'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'

import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import AnnouncementsStore from '~/client/src/shared/stores/domain/Announcements.store'

import MobileInitialState from '../../stores/MobileInitialState'
import MobileCommonStore from '../../stores/ui/MobileCommon.store'
import AnnouncementCreationForm from '../AnnouncementCreationForm/AnnouncementCreationForm'

interface IProps {
  common?: MobileCommonStore
  state?: MobileInitialState
  announcementsStore?: AnnouncementsStore
  id: string // the announcement id
}

export const NEW_ANNOUNCEMENT_ID = 'new'

interface IRouteParams {
  announcementId: string
}

/**
 * The AnnouncementView component
 * Always renders `AnnouncementCreationForm` in read-only mode by default, except when creating a new announcement.
 *
 * @param {IRouteParams} match.params.announcementId - The announcement ID; admins can pass `new` to create a new announcement.
 */
@inject('common', 'state', 'announcementsStore')
@observer
class AnnouncementView extends React.Component<
  IProps & RouteComponentProps<IRouteParams>
> {
  @observable private isEditing: boolean = false

  public constructor(props: IProps & RouteComponentProps<IRouteParams>) {
    super(props)

    // Admins can create a new announcement
    if (this.announcementId === NEW_ANNOUNCEMENT_ID) {
      if (this.isAdmin) {
        this.isEditing = true
      } else {
        // Non-admins trying to create a new announcement are redirected
        this.handleClose()
      }
    }
  }

  private spinner = (
    <div className="full-height row x-center y-center">
      <Spinner />
    </div>
  )

  private get announcementId() {
    return this.props.match.params.announcementId
  }

  private get isAdmin() {
    return this.props.state.userActiveProjectSettings.isAdmin
  }

  @action.bound
  private toggleEdit() {
    this.isEditing = this.isAdmin ? !this.isEditing : false
  }

  private handleClose = () => {
    this.props.common.goBack()
  }

  public render() {
    const { announcementsStore } = this.props

    if (!announcementsStore.isDataReceived) {
      return this.spinner
    }

    const announcement = announcementsStore.byId.get(this.announcementId)

    // When announcement is not found, show a message
    if (!announcement && !this.isEditing) {
      return this.renderNotFound()
    }

    // If the announcement is found, show it
    // When editing without an announcement, create a new one
    return announcement || this.isEditing ? (
      <div>
        <AnnouncementCreationForm
          displayedAnnouncement={announcement}
          selectedDate={
            announcement ? new Date(announcement.startDate) : new Date()
          }
          isReadOnly={!this.isEditing}
          onClose={this.handleClose}
        />

        {/* Admins get the Edit button in read mode */}
        {!this.isEditing && this.isAdmin && (
          <div className="px16">
            <div className="button-container row py12 px16 no-grow">
              <div
                className="bg-color-blue-brand brada4 h32 row x-center y-center text white extra-large bold"
                onClick={this.toggleEdit}
              >
                {Localization.translator.edit_verb}
              </div>
            </div>
          </div>
        )}
      </div>
    ) : (
      this.spinner
    )
  }

  private renderNotFound() {
    return (
      <div className="col full-height">
        <div className="row y-center bb-light-grey py4 no-grow bg-palette-brand-lightest">
          <Icons.Cross
            className="no-grow cross-icon px20"
            onClick={this.handleClose}
          />
        </div>
        <div className="col full-height">
          <div className="col flex-item x-center y-center px16">
            <div className="text large center">
              {Localization.translator.announcementNotFound}
            </div>
          </div>

          {/* Admins get the New Announcement button in the 'not found' page */}
          {this.isAdmin && (
            <div className="button-container row py12 px16 no-grow">
              <div
                className="bg-color-blue-brand brada4 h32 row x-center y-center text white extra-large bold"
                onClick={this.toggleEdit}
              >
                {Localization.translator.newAnnouncement}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(AnnouncementView)
