import * as React from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { isAndroid, isIOS } from 'react-device-detect'

import { LocalStorageKey } from '~/client/src/shared/enums/LocalStorageKey'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import { SHOW_ONE_TIME_POPUP } from '~/client/src/shared/stores/EventStore/eventConstants'

import { androidStoreURL, iosStoreURL } from '../../constants/storesInfo'
import PopupContent from './PopupContent'

const appleBadge = '/static/images/badges/apple.svg'
const googleBadge = '/static/images/badges/google.svg'

const ANIMATION_DURATION = 300

interface IProps {
  eventsStore?: EventsStore
}

@inject('eventsStore')
@observer
export default class OneTimePopup extends React.Component<IProps> {
  private readonly clearPostEventCallback: () => void

  @observable private _isDismissed: boolean
  @observable private _showPopup: boolean

  public constructor(props: IProps) {
    super(props)

    this._showPopup = !this.isDismissed

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      this.onExternalEvent,
    )
  }

  @action.bound
  public onExternalEvent(eventContext: EventContext) {
    const [eventType] = eventContext.event

    if (SHOW_ONE_TIME_POPUP === eventType) {
      this._isDismissed = false
      this._showPopup = true
    }
  }

  public get isDismissed(): boolean {
    if (this._isDismissed === undefined) {
      const rawValue = localStorage.getItem(LocalStorageKey.OneTimePopup)
      if (!rawValue) {
        return false
      }

      this._isDismissed = !!rawValue
    }
    return this._isDismissed
  }

  @action.bound
  public dismiss(isUnderstood = false) {
    if (isUnderstood) {
      localStorage.setItem(LocalStorageKey.OneTimePopup, 'dismissed')
    }
    this._isDismissed = true
    setTimeout(() => {
      this._showPopup = false
    }, ANIMATION_DURATION)
  }

  public render() {
    // Never show in the webview
    if (window.ReactNativeWebView) return null

    if ((isAndroid || isIOS) && this._showPopup) {
      return (
        <PopupContent
          actionButton={this.actionButton}
          onDismiss={this.dismiss}
          open={!this.isDismissed}
        />
      )
    }

    return null
  }

  public componentWillUnmount() {
    this.clearPostEventCallback()
  }

  private get actionButton() {
    const i18n = Localization.translator
    const url = isAndroid ? androidStoreURL : iosStoreURL
    const badge = isAndroid ? googleBadge : appleBadge
    const altText = isAndroid ? i18n.getAppOnGoogle : i18n.getAppOnApple
    return (
      <a href={url} target="_blank">
        <img src={badge} className="max-h-w" alt={altText} />
      </a>
    )
  }
}
