import { action, observable } from 'mobx'

import { IFilters } from '~/client/src/shared/stores/InitialState'

// localization: no display text to translate

export default class EntityNameFilterStore {
  @observable public searchKey: string = ''
  @observable public isShown: boolean = false

  public get isActive(): boolean {
    return !!this.filters.searchKey
  }

  public constructor(
    protected readonly filters: IFilters,
    private readonly onShowChanged?: (isShown: boolean) => void,
  ) {
    if (filters) {
      this.searchKey = filters.searchKey
    }
  }

  @action.bound
  public update() {
    this.filters.searchKey = this.searchKey
  }

  @action.bound
  public toggle() {
    this.isShown = !this.isShown
    if (this.onShowChanged) {
      this.onShowChanged(this.isShown)
    }
  }

  @action.bound
  public close() {
    this.isShown = false
    if (this.onShowChanged) {
      this.onShowChanged(false)
    }
  }
}
