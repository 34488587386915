import * as React from 'react'

import { action, computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { FilterType } from '~/client/graph'
import CompactDialog from '~/client/src/shared/components/CompactDialog/CompactDialog'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import { CustomFilterDialogModes } from '~/client/src/shared/enums/CustomFilterDialogModes'
import ActivityCodesStore from '~/client/src/shared/stores/domain/ActivityCodes.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import CustomActivityListFiltersStore from '~/client/src/shared/stores/domain/CustomActivityListFilters.store'

import ActivityFiltersPageStore from '../ActivityFiltersPage.store'

export interface IProps {
  activityCodesStore?: ActivityCodesStore
  customActivityListFiltersStore?: CustomActivityListFiltersStore
  companiesStore?: CompaniesStore
  filtersPageStore: ActivityFiltersPageStore
}

const customFilter = 'Custom filter'
const workPackageName = 'Work package name'

@inject(
  'activityCodesStore',
  'customActivityListFiltersStore',
  'companiesStore',
)
@observer
export default class SaveFilterDialog extends React.Component<IProps> {
  public render() {
    const {
      editableFilterName,
      isSaveFilterDialogShown,
      filterDialogMode: mode,
    } = this.props.filtersPageStore

    const isDeleteBtnVisible = mode === CustomFilterDialogModes.Edit

    return (
      <CompactDialog
        isShown={isSaveFilterDialogShown}
        onHide={this.closeSaveFiltersDialog}
        title={customFilter}
      >
        <div className="py20">
          <StruxhubInput
            id="save-filter-name-input"
            label={workPackageName}
            isRequiredTextHidden={true}
            value={editableFilterName}
            onChange={this.onNameChange}
            onValueReset={this.onNameReset}
          />
          <div className="mt10">
            <span className="text extra-large bold mr10">Selected filters</span>
            <span className="text extra-large light">
              ({this.filtersCount} filters)
            </span>
          </div>
          {this.filtersDescriptions.map((label, idx) => (
            <div className="row py10" key={idx}>
              <div className="no-grow px10 text primary-blue">•</div>
              <div className="text large ellipsis">{label}</div>
            </div>
          ))}

          <div className="custom-filter-dialog-controls mt5">
            <button
              className={classList({
                'delete-btn btn save-button': true,
                hide: !isDeleteBtnVisible,
              })}
              onClick={this.applyDeletingFilter}
            >
              Delete
            </button>

            <button
              className="btn apply-button"
              onClick={this.applySavingFilter}
            >
              Save
            </button>
          </div>
        </div>
      </CompactDialog>
    )
  }

  @action.bound
  private onNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { filtersPageStore } = this.props
    filtersPageStore.editableFilterName = event.target.value || ''
  }

  @action.bound
  private onNameReset() {
    const { filtersPageStore } = this.props
    filtersPageStore.editableFilterName = ''
  }

  @computed
  private get filtersCount(): number {
    const { saveFilterDialogInstance } = this.props.filtersPageStore

    if (!saveFilterDialogInstance) {
      return 0
    }

    const { filtersByFilterType } = saveFilterDialogInstance
    return Object.values(filtersByFilterType).reduce(
      (sum, codes) => sum + codes.length,
      0,
    )
  }

  @computed
  private get filtersDescriptions(): string[] {
    const { byId: codesMap } = this.props.activityCodesStore
    const { saveFilterDialogInstance } = this.props.filtersPageStore

    if (!saveFilterDialogInstance) {
      return []
    }
    const { filtersByFilterType } = saveFilterDialogInstance

    return Object.keys(filtersByFilterType).map(filterType => {
      const values = filtersByFilterType[filterType]
      if (filterType === FilterType.Company) {
        return values
          .map(id => this.props.companiesStore.getCompanyNameById(id))
          .join(', ')
      }

      return values
        .map(id => {
          const code = codesMap.get(id)
          return code ? code.name : ''
        })
        .join(', ')
    })
  }

  @action.bound
  private closeSaveFiltersDialog() {
    const store = this.props.filtersPageStore
    store.isSaveFilterDialogShown = false
    store.editableFilterName = ''
    store.saveFilterDialogInstance = null
  }

  @action.bound
  private applySavingFilter() {
    const store = this.props.filtersPageStore
    store.saveFilterDialogInstance.name = store.editableFilterName
    this.props.customActivityListFiltersStore.saveOne(
      store.saveFilterDialogInstance,
    )
    this.closeSaveFiltersDialog()
  }

  @action.bound
  private applyDeletingFilter() {
    const { isCustomFilterEditingDisabled, selectedCustomFilter } =
      this.props.filtersPageStore
    if (isCustomFilterEditingDisabled) {
      return
    }

    this.props.customActivityListFiltersStore.removeOne(selectedCustomFilter.id)
    this.closeSaveFiltersDialog()
  }
}
