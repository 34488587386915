import * as React from 'react'

import { inject, observer } from 'mobx-react'

import MobileInitialState from '~/client/src/mobile/stores/MobileInitialState'
import {
  Content,
  Footer,
  Header,
  Padding,
  Thickness,
  View,
} from '~/client/src/shared/components/Layout'
import Text from '~/client/src/shared/components/Text'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import MobileCommonStore from '../../stores/ui/MobileCommon.store'
import ProjectBlock from './components/ProjectBlock/ProjectBlock'

import './Projects.scss'

interface IProjectsView {
  state?: MobileInitialState
  projectsStore?: ProjectsStore
  common?: MobileCommonStore
  companiesStore?: CompaniesStore
  userProjectsStore?: UserProjectsStore
}

@inject(
  'state',
  'projectsStore',
  'common',
  'companiesStore',
  'userProjectsStore',
)
@observer
export class ProjectsView extends React.Component<IProjectsView> {
  public constructor(props: IProjectsView) {
    super(props)
  }

  public componentDidMount() {
    this.props.common.showNavBar()
  }

  public render() {
    const {
      state: { userActiveProjectSettings },
      companiesStore,
      projectsStore: { list: projects },
    } = this.props

    const projectBlocks = projects.map(p => {
      return (
        <Padding padding={Thickness.fromTRBL(0, 0, 10, 10)} key={p.id}>
          <ProjectBlock project={p} />
        </Padding>
      )
    })
    return (
      <View>
        <Header>
          <Padding padding={Thickness.fromTRBL(20, 0, 10, 18)}>
            <Text
              textStyle={{
                fontSize: 18,
                fontWeight: 'bold',
              }}
            >
              {companiesStore.getCompanyNameById(
                userActiveProjectSettings?.companyId,
              )}
            </Text>
          </Padding>
          <Padding padding={Thickness.fromTRBL(0, 0, 10, 18)}>
            <div className="bb-light-input-border" />
          </Padding>
        </Header>
        <Content scrollable={true}>
          <Padding padding={Thickness.symmetric(18, 10)}>
            {projectBlocks}
          </Padding>
        </Content>
        <Footer>{null}</Footer>
      </View>
    )
  }
}
