import * as React from 'react'

import { Spinner } from '@blueprintjs/core'
import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { RouteComponentProps, withRouter } from 'react-router'

import { NotificationType } from '~/client/graph'
import BottomNavigationNavBar from '~/client/src/mobile/components/BottomNavigationNavBar/BottomNavigationNavBar'
import MobileCommonStore from '~/client/src/mobile/stores/ui/MobileCommon.store'
import UnsafeNotificationItem from '~/client/src/mobile/views/Notifications/components/NotificationItem'
import { withErrorBoundary } from '~/client/src/shared/components/ErrorBoundary'
import * as Icons from '~/client/src/shared/components/Icons'
import {
  Content,
  Footer,
  Header,
  View,
} from '~/client/src/shared/components/Layout'
import BaseNotification from '~/client/src/shared/models/Notification'
import {
  INIT_APP,
  INIT_APP_2,
  LOAD_NOTIFICATIONS,
  VIEW_NOTIFICATION,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import DeliveryStatusChangesStore from '~/client/src/shared/stores/domain/DeliveryStatusChanges.store'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'
import NotificationsStore from '~/client/src/shared/stores/domain/Notifications.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import {
  isDeliveryType,
  isFlagType,
  isFollowingType,
  isPermitType,
  isRFIType,
  isScheduleCommentType,
  isStatusUpdateType,
} from '~/client/src/shared/types/NotificationTypes'
import {
  getSearchParam,
  removeFromSearchParam,
} from '~/client/src/shared/utils/urlutils'

import MobileEventStore from '../../stores/EventStore/MobileEvents.store'
import ListComponent from './components/List'

import './NotificationsList.scss'

const NotificationItem = withErrorBoundary(UnsafeNotificationItem)

interface IProps extends RouteComponentProps<any> {
  eventsStore?: MobileEventStore
  common?: MobileCommonStore
  deliveriesStore?: DeliveriesStore
  deliveryStatusChangesStore?: DeliveryStatusChangesStore
  companiesStore?: CompaniesStore
  graphExecutorStore?: GraphExecutorStore
  projectDateStore?: ProjectDateStore
}

interface IFilter {
  icon
  className: string
  isType?: (type: NotificationType) => boolean
  onClick?: () => void
}

@inject(
  'eventsStore',
  'common',
  'deliveriesStore',
  'deliveryStatusChangesStore',
  'companiesStore',
  'graphExecutorStore',
)
@observer
class NotificationsListComponent extends React.Component<IProps> {
  private readonly filters: IFilter[] = [
    {
      icon: 'All',
      className: 'black-font',
    },
    {
      icon: <Icons.Flag className="icon-red" />,
      isType: isFlagType,
      className: 'flag-font',
    },
    {
      icon: <Icons.Rfi />,
      isType: isRFIType,
      className: 'rfi-font',
    },
    {
      icon: <Icons.GeneralForm />,
      isType: isPermitType,
      className: 'blue-font',
    },
    {
      icon: <Icons.DateChangeBlue />,
      isType: isScheduleCommentType,
      className: 'schedule-comment-font',
    },
    {
      icon: <Icons.Delivery />,
      isType: isDeliveryType,
      className: 'blue-font',
    },
    {
      icon: <Icons.StatusBadge />,
      isType: isStatusUpdateType,
      className: 'blue-font',
    },
    {
      icon: <Icons.UnfilledStar />,
      isType: isFollowingType,
      className: 'blue-font',
    },
  ]

  private store: NotificationsStore

  @observable private activeFilter = this.filters[0].isType

  public constructor(props: IProps) {
    super(props)

    this.filters.forEach(filter => {
      filter.onClick = () => this.setActiveFilter(filter.isType)
    })

    this.store = new NotificationsStore(
      props.eventsStore,
      props.deliveriesStore,
      props.deliveryStatusChangesStore,
      props.companiesStore,
      props.graphExecutorStore,
      props.projectDateStore,
      undefined,
      this.showNotificationByQueryString,
    )
  }

  public componentWillUnmount() {
    const { appState } = this.props.eventsStore
    appState.isNotificationsArchiveModeActive = false
    this.store.disposeNotifications()
  }

  public componentDidMount() {
    this.props.common.showNavBar()
  }

  /**
   * Show notification if `id` in query string matches a notification's `entityId`
   */
  private showNotificationByQueryString = () => {
    const VIEW_BY_ID = 'id'
    const notificationToDisplay = this.store.getLatestByEntityId(
      getSearchParam(VIEW_BY_ID),
    )
    if (notificationToDisplay) {
      // Remove entityId from the URL so the user can navigate back to the list
      removeFromSearchParam(VIEW_BY_ID)
      this.props.eventsStore.dispatch(VIEW_NOTIFICATION, notificationToDisplay)
    }
  }

  public componentDidUpdate(): void {
    // Show notification if `id` in query string changes
    this.showNotificationByQueryString()
  }

  @computed
  private get notifications(): BaseNotification[] {
    const { all, filteredLatestForEachEntityFromActiveProject: latest } =
      this.store
    const { isNotificationsArchiveModeActive } = this.props.eventsStore.appState

    const notifications = isNotificationsArchiveModeActive
      ? all.sort((a, b) => b.createdAt - a.createdAt)
      : latest
    return notifications.filter(
      n => Boolean(n.isArchived) === isNotificationsArchiveModeActive,
    )
  }

  public render() {
    return (
      <View>
        <Header className="pa20 bb-light-grey row notification-filter overflow-x-auto">
          {this.filters.map((filter, index) => {
            return (
              <button
                key={index}
                onClick={filter.onClick}
                className={classList({
                  [filter.className]: true,
                  'notification-filter-item no-outline': true,
                  'grey-font': !this.getFilteredNotifications(filter.isType)
                    .length,
                  'notification-filter-item-active':
                    filter.isType === this.activeFilter,
                })}
              >
                {filter.icon} (
                {this.getFilteredUnreadNotificationsCount(filter.isType)})
              </button>
            )
          })}
        </Header>
        <Content scrollable={true} className="notification-list">
          <ListComponent
            data={this.getFilteredNotifications(this.activeFilter)}
            renderEmpty={this.renderEmpty}
            renderItem={this.renderNotification}
          />
        </Content>
        <Footer>
          <BottomNavigationNavBar />
        </Footer>
      </View>
    )
  }

  public renderNotification = (notification: BaseNotification) => {
    return (
      <NotificationItem key={notification.id} notification={notification} />
    )
  }

  @action.bound
  public renderEmpty() {
    return (
      <div className="row x-center y-center bg-light-cool-grey pa20 ba-light-cool-grey">
        {this.isLoading ? (
          <div className="spinner-holder">
            <Spinner size={20} />
          </div>
        ) : (
          <div className="text extra-large center">No Notifications!</div>
        )}
      </div>
    )
  }

  private get isLoading() {
    const { loading } = this.props.eventsStore.appState
    return [INIT_APP, INIT_APP_2, LOAD_NOTIFICATIONS].some(e => loading.get(e))
  }

  private setActiveFilter(filter: (type: NotificationType) => boolean) {
    this.activeFilter = filter
  }

  private getFilteredNotifications(
    isType: (type: NotificationType) => boolean,
  ) {
    if (isType) {
      return this.notifications.filter(n => isType(n.type))
    }

    return this.notifications
  }

  private getFilteredUnreadNotificationsCount(
    isType: (type: NotificationType) => boolean,
  ) {
    return this.getFilteredNotifications(isType).filter(n => !n.wasRead).length
  }
}

// workaround for strange error in mobile routes
// see https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18999
export default withRouter(NotificationsListComponent) as any
