import * as React from 'react'

import { inject, observer } from 'mobx-react'

import routes from '~/client/src/mobile/constants/mobileRoutes'
import MobileCommonStore from '~/client/src/mobile/stores/ui/MobileCommon.store'
import Activity from '~/client/src/shared/models/Activity'

import './PhotoActivityCaption.scss'

interface IProps {
  activity: Activity
  rightSideContent: string
  common?: MobileCommonStore
}

@inject('common')
@observer
export default class PhotoActivityCaption extends React.Component<IProps> {
  public render() {
    const { activity } = this.props

    return (
      <div className="photo-activity-caption" onClick={this.handleClick}>
        <span className="photo-activity-name text extra-large">
          {activity.name}
        </span>
        <span className="separator col no-grow">|</span>
        <span className="photo-activity-id text extra-large">
          {activity.code}
        </span>
      </div>
    )
  }

  private handleClick = () => {
    const { common, activity } = this.props
    common._displayView(routes.ACTIVITY_DETAIL.replace(':code', activity.code))
  }
}
