import React from 'react'

import { Route, Switch } from 'react-router-dom'

import RoutesSlider from './RoutesSlider'

export default class SwitchWithSlide extends React.Component {
  public render() {
    return <Route render={this.renderSlider} />
  }

  private renderSlider = ({ location, history }) => (
    <RoutesSlider pathname={location.pathname} action={history.action}>
      <Switch location={location}>{this.props.children}</Switch>
    </RoutesSlider>
  )
}
