import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import MultiSelectFilter, {
  IMultiSelectOption,
} from '~/client/src/shared/components/MultiSelectFilter/MultiSelectFilter'

interface IProps {
  options: IMultiSelectOption[]
  filterType?: string
  values: string[]
  title: string
  onChange(values: string[], filterType?: string): void
}

@observer
export default class ActivityFiltersMultiSelect extends MultiSelectFilter<IProps> {
  protected isOnlyButtonsShown: boolean = false

  public render() {
    return (
      <div>
        <div className="row no-flex-children x-between y-center bb-light-grey pb10">
          {this.title}
          {this.selectAllButton}
        </div>
        <div className="py10">{this.options}</div>
      </div>
    )
  }

  protected get title() {
    const { title } = this.props
    return (
      <div className="text extra-large bold">
        <span>{title}</span>
        <Icon className="filter-icon" icon={IconNames.FILTER} />
      </div>
    )
  }

  protected get selectAllButton() {
    const { options, values } = this.props
    const isAllSelected = options.every(({ value }) => values.includes(value))

    const clickHandler = isAllSelected
      ? this.onClearAllClicked
      : this.onSelectAllClicked
    const caption = isAllSelected ? 'Clear All' : 'Select All'

    return (
      <div
        className={classList({
          'select-all-button': true,
          'blue-btn': !isAllSelected,
          'white-btn': isAllSelected,
        })}
        onClick={clickHandler}
      >
        {caption}
      </div>
    )
  }

  public onClearAllClicked = () => {
    const { onChange, filterType } = this.props
    onChange([], filterType)
  }
}
