import * as React from 'react'

import { observer } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'

interface IProps {
  isActive?: boolean
  className?: string
  onClick?: () => void
}

@observer
export default class FilterIcon extends React.Component<IProps> {
  public static defaultProps = {
    isActive: false,
    className: '',
    onClick: () => null,
  }

  public render() {
    const { isActive, className, onClick } = this.props

    return (
      <Icons.Filter
        className={`${isActive ? '' : 'icon-grey'} ${className}`}
        onClick={onClick}
      />
    )
  }
}
