import * as React from 'react'

import { inject, observer } from 'mobx-react'

import ActivityPreset from '~/client/src/shared/models/ActivityPreset'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'

export interface IProps {
  activityPreset?: ActivityPreset
  activitiesStore?: ActivitiesStore
}

@inject('activitiesStore')
@observer
export default class MobileActivityPresetLabel extends React.Component<IProps> {
  public render() {
    const availableActivities = this.props.activitiesStore.list.filter(a =>
      this.props.activityPreset.activities.includes(a.code),
    )
    const activitiesAmount = availableActivities.length
    const { name } = this.props.activityPreset

    return (
      <span>
        {name}
        <span className="ml5 text extra-large light">({activitiesAmount})</span>
      </span>
    )
  }
}
