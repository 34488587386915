import * as React from 'react'

import { observer } from 'mobx-react'

import * as Icons from '~/client/src/shared/components/Icons'
import PhotoSortingTypes from '~/client/src/shared/enums/PhotoSortingTypes'
import { enumToList } from '~/client/src/shared/utils/converters'

import SortingTypeItem from './components/SortingTypeItem'

@observer
export default class PhotoSorter extends React.Component {
  public render() {
    return (
      <div className="px10 bb-light-grey">
        {enumToList(PhotoSortingTypes).map(sortingType => (
          <SortingTypeItem key={sortingType} sortingType={sortingType} />
        ))}
        <Icons.BlueSearch className="float-right m10" />
      </div>
    )
  }
}
