import * as React from 'react'

import { inject } from 'mobx-react'

import MobileCommonStore from '~/client/src/mobile/stores/ui/MobileCommon.store'
import Photo from '~/client/src/shared/models/Photo'
import CategoriesOfVarianceStore from '~/client/src/shared/stores/domain/CategoriesOfVariance.store'
import FlagsStore from '~/client/src/shared/stores/domain/Flags.store'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import RfisStore from '~/client/src/shared/stores/domain/Rfis.store'
import SafetyHazardsStore from '~/client/src/shared/stores/domain/SafetyHazards.store'
import ScheduleCommentsStore from '~/client/src/shared/stores/domain/ScheduleComments.store'

import './Photo.scss'

interface IProps {
  messagesStore?: MessagesStore
  flagsStore?: FlagsStore
  rfisStore?: RfisStore
  scheduleCommentsStore?: ScheduleCommentsStore
  categoriesOfVarianceStore?: CategoriesOfVarianceStore
  safetyHazardsStore?: SafetyHazardsStore
  common?: MobileCommonStore
  photo: Photo
  width?: number
  height?: number
}

interface IState {
  isReady: boolean
}

@inject(
  'common',
  'messagesStore',
  'flagsStore',
  'rfisStore',
  'scheduleCommentsStore',
  'categoriesOfVarianceStore',
  'safetyHazardsStore',
)
export default class PhotoView extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)
    this.state = {
      isReady: false,
    }
  }

  public render() {
    const { photo, width, height } = this.props
    return (
      <div
        className="photo-component indication-click"
        style={{
          width,
          height: this.state.isReady ? height : 0,
        }}
      >
        <img
          onClick={this.handleClick}
          src={photo.url}
          className="photo"
          onLoad={this.onLoad}
        />
      </div>
    )
  }

  private findInStore(store) {
    if (!store || !store.list) {
      return
    }

    return store.list.find(entity => {
      if (entity.activityObjectId !== this.props.photo.activityObjectId) {
        return
      }
      return this.props.messagesStore.list.some(
        m =>
          m.threadId === entity.threadId && m.photoId === this.props.photo.id,
      )
    })
  }

  private handleClick = () => {
    const {
      flagsStore,
      rfisStore,
      scheduleCommentsStore,
      categoriesOfVarianceStore,
      safetyHazardsStore,
      photo,
      common,
    } = this.props

    const flag = this.findInStore(flagsStore)
    if (flag) {
      return common.displayFlagView(flag)
    }

    const rfi = this.findInStore(rfisStore)
    if (rfi) {
      return common.displayRfiView(rfi)
    }

    const sc = this.findInStore(scheduleCommentsStore)
    if (sc) {
      return common.displayScheduleCommentView(rfi)
    }

    const cov = this.findInStore(categoriesOfVarianceStore)
    if (cov) {
      return common.displayCategoryOfVarianceView(cov)
    }

    const sh = this.findInStore(safetyHazardsStore)
    if (sh) {
      return common.displaySafetyHazardView(sh)
    }

    return common.displayPhotoView(photo)
  }

  private onLoad = () => {
    this.setState({
      isReady: true,
    })
  }
}
