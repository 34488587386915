import * as React from 'react'

import { Colors, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import AnnouncementEditionFormStore from '~/client/src/shared/components/AnnouncementEditionForm/AnnouncementEditionForm.store'
import AnnouncementEditionFormContent from '~/client/src/shared/components/AnnouncementEditionForm/AnnouncementEditionFormContent'
import AnnouncementEditionFormDetails from '~/client/src/shared/components/AnnouncementEditionForm/AnnouncementEditionFormDetails'
import * as Icons from '~/client/src/shared/components/Icons'
import WorkflowCardLocationLabel from '~/client/src/shared/components/WorkflowCard/LocationLabel'
import Announcement from '~/client/src/shared/models/Announcement'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import AnnouncementAssignmentsStore from '~/client/src/shared/stores/domain/AnnouncementAssignments.store'
import AnnouncementsStore from '~/client/src/shared/stores/domain/Announcements.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import './AnnouncementCreationForm.scss'

interface IProps {
  displayedAnnouncement?: Announcement
  isReadOnly: boolean
  selectedDate: Date
  onClose: () => void

  projectDateStore?: ProjectDateStore
  announcementsStore?: AnnouncementsStore
  announcementAssignmentsStore?: AnnouncementAssignmentsStore
  eventsStore?: EventsStore
}

const newAnnouncement = 'New announcement'
const editingAnnouncement = 'Editing announcement'
const siteAnnouncement = 'Site announcement'

enum AnnouncementSection {
  form = 'Announcement form',
  message = 'Announcement message',
}

@inject(
  'projectDateStore',
  'eventsStore',
  'announcementsStore',
  'announcementAssignmentsStore',
)
@observer
export default class AnnouncementCreationForm extends React.Component<IProps> {
  private store: AnnouncementEditionFormStore
  private sectionsToggleState = observable(new Map<string, boolean>())

  public constructor(props: IProps) {
    super(props)

    this.store = new AnnouncementEditionFormStore(
      props.eventsStore,
      props.projectDateStore,
      props.announcementsStore,
      props.announcementAssignmentsStore,
      props.displayedAnnouncement,
    )

    Object.values(AnnouncementSection).forEach(section => {
      this.sectionsToggleState.set(section, true)
    })
  }

  public render() {
    const { isReadOnly } = this.props
    const {
      isOrderSetUpDisplayed,
      isDatePickerDisplayed,
      isLocationPickerDisplayed,
    } = this.store
    const isContentShown =
      !isDatePickerDisplayed &&
      !isOrderSetUpDisplayed &&
      !isLocationPickerDisplayed

    return (
      <div
        className={classList({
          'mobile-announcement-creation-form': true,
          'is-read-only': isReadOnly,
          'full-height': !isContentShown,
        })}
      >
        {this.renderContent()}
      </div>
    )
  }

  private renderContent() {
    const {
      onClose,
      isReadOnly,
      projectDateStore: { getPronounWithMonthDayAndTimeString },
      selectedDate,
    } = this.props
    const { dateToAppear, startDate, location } =
      this.store.editableAnnouncement
    const locations = location ? [location] : []
    const {
      isOrderSetUpDisplayed,
      isDatePickerDisplayed,
      isLocationPickerDisplayed,
    } = this.store
    const isContentShown =
      !isDatePickerDisplayed &&
      !isOrderSetUpDisplayed &&
      !isLocationPickerDisplayed

    return (
      <>
        <div className="row y-center bb-light-grey py4 no-grow bg-palette-brand-lightest">
          <Icons.Cross
            className="no-grow cross-icon px20"
            onClick={this.handleCancel}
          />
          <div className="text bold small-header">{this.headerText}</div>
          {isReadOnly && (
            <div className="row no-grow header no-white-space-wrap mr15">
              {getPronounWithMonthDayAndTimeString(dateToAppear || startDate)}
            </div>
          )}
        </div>
        {!isReadOnly && (
          <div className="announcement-creation-form-details full-height">
            {isContentShown &&
              this.renderSectionHeader(AnnouncementSection.form)}
            {this.sectionsToggleState.get(AnnouncementSection.form) && (
              <AnnouncementEditionFormDetails
                selectedDate={selectedDate}
                store={this.store}
                onClose={onClose}
              />
            )}
          </div>
        )}
        {isContentShown && (
          <>
            {!isReadOnly &&
              this.renderSectionHeader(AnnouncementSection.message)}
            {this.sectionsToggleState.get(AnnouncementSection.message) && (
              <>
                <AnnouncementEditionFormContent
                  store={this.store}
                  isReadOnly={isReadOnly}
                />
                {isReadOnly && (
                  <div className="nowrap pt5 pb20 pl20">
                    <WorkflowCardLocationLabel
                      locations={locations}
                      isOneColor={false}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </>
    )
  }

  private get headerText() {
    const { isReadOnly } = this.props
    const { isEditMode } = this.store
    if (!isEditMode) {
      return newAnnouncement
    }

    return isReadOnly ? siteAnnouncement : editingAnnouncement
  }

  private renderSectionHeader(sectionName: string, className?: string) {
    const iconName = this.sectionsToggleState.get(sectionName)
      ? IconNames.CARET_DOWN
      : IconNames.CARET_RIGHT

    return (
      <div
        className={classList({
          'section-bar row text uppercase white py8 px16 bg-grey-scale-light':
            true,
          [className]: !!className,
        })}
        onClick={this.toggleSection.bind(this, sectionName)}
      >
        {sectionName && <Icon icon={iconName} color={Colors.WHITE} />}
        <Icons.MegaphoneFilled className="section-icon mr8 no-grow" />
        <span>{sectionName}</span>
      </div>
    )
  }

  private toggleSection = (sectionName: string) => {
    this.sectionsToggleState.set(
      sectionName,
      !this.sectionsToggleState.get(sectionName),
    )
  }

  @action.bound
  private handleCancel = () => {
    this.store.resetCurrentChanges()
    this.props.onClose()
  }
}
