import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import mobileRoutes from '~/client/src/mobile/constants/mobileRoutes'
import User from '~/client/src/shared/models/User'

import './SidebarHeader.scss'

export default function SidebarHeader(props: {
  projectName: string
  user: User
  onClick: (isDisabled: boolean, route: string) => void
}) {
  return (
    <div className="sidebar-header">
      <div
        className="row x-center y-center bg-white h36 brada4 mt15 mr15 ml15 pl8 ba-grey indication-click relative"
        onClick={() => {
          props.onClick(false, mobileRoutes.PROJECTS)
        }}
      >
        <span className="text large line-extra-large">{props.projectName}</span>
        <div className="arrow-down">
          <Icon icon={IconNames.CHEVRON_DOWN} />
        </div>
      </div>
    </div>
  )
}
