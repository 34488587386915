import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'

import { Loader } from '~/client/src/shared/components/Loader'
import UsersDirectory from '~/client/src/shared/components/UsersDirectory/UsersDirectory'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'

import BottomNavigationNavBar from '../../components/BottomNavigationNavBar/BottomNavigationNavBar'
import MobileView from '../../components/MobileView'
import MobileInitialState from '../../stores/MobileInitialState'
import MobileCommonStore from '../../stores/ui/MobileCommon.store'

interface IProps {
  state?: MobileInitialState
  common?: MobileCommonStore
}

@inject('state', 'common')
@observer
class UsersDirectoryView extends React.Component<IProps & RouteComponentProps> {
  public componentDidMount() {
    this.props.common.hideNavBar()
  }

  public render() {
    return (
      <MobileView content={this.content} footer={<BottomNavigationNavBar />} />
    )
  }

  private get content(): JSX.Element {
    const { state, common } = this.props
    const { loading } = state

    const isProjectLoading = [
      e.ACTIVATE_PROJECT,
      e.GET_PROJECT_MEMBERS,
      e.GET_USERS_FROM_PROCORE,
      e.LOAD_AND_LISTEN_TO_COMPANIES,
    ].some(event => loading.get(event))

    if (isProjectLoading) {
      return <Loader />
    }

    return <UsersDirectory onUserRowClick={common.displayUserProfileView} />
  }
}

export default withRouter(UsersDirectoryView)
