import * as React from 'react'

import { observer } from 'mobx-react'

interface IProps {
  caption?: string
  backClicked?: any
}

@observer
class MobileDetailsHeader extends React.Component<IProps> {
  public render() {
    const leftArrowIcon = '/static/icons/back-arrow.svg'

    const { caption } = this.props

    return (
      <div className="col">
        <div className="row pa20 bb-light-grey bg-white">
          <div className="col no-grow pr20">
            <img src={leftArrowIcon} onClick={this.props.backClicked} />
          </div>
          <div className="col" onClick={this.props.backClicked}>
            <div className="text large bold">{caption}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default MobileDetailsHeader
