import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import MobileInitialState from '~/client/src/mobile/stores/MobileInitialState'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import './MovedToCurrentSectionTooltip.scss'

interface IProps {
  state?: MobileInitialState
  projectDateStore?: ProjectDateStore
}

@inject('state', 'projectDateStore')
@observer
export default class MovedToCurrentSectionTooltip extends React.Component<IProps> {
  public render() {
    const { movedToCurrentSectionActivityName, movingToCurrentSectionTime } =
      this.props.state.activityList

    const { getMonthDayYearAndTimeToDisplay } = this.props.projectDateStore
    return (
      <div
        className={classList({
          'col pa15 moved-to-current-section-tooltip': true,
          hidden: !movedToCurrentSectionActivityName,
        })}
      >
        <div className="row">
          <div className="text banner-gold bold">IN PROGRESS</div>
          <div className="text right dark-gray">
            {getMonthDayYearAndTimeToDisplay(movingToCurrentSectionTime)}
          </div>
        </div>
        <div className="pt5 text large white bold">
          Activity {movedToCurrentSectionActivityName} has been moved to current
          activities.{' '}
        </div>
      </div>
    )
  }
}
