import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import MobileEventStore from '~/client/src/mobile/stores/EventStore/MobileEvents.store'
import MobileDeliveryNotificationActions from '~/client/src/mobile/views/Notifications/components/MobileDeliveryNotificationActions'
import * as Icons from '~/client/src/shared/components/Icons'
import { HGap } from '~/client/src/shared/components/Layout'
import NotificationAuthor from '~/client/src/shared/components/NotificationAuthor/NotificationAuthor'
import NotificationDocuments from '~/client/src/shared/components/NotificationDocuments'
import NotificationEntityName from '~/client/src/shared/components/NotificationEntityName'
import NotificationLabel from '~/client/src/shared/components/NotificationLabel'
import NotificationMessage from '~/client/src/shared/components/NotificationMessage'
import NotificationTimestampComponent from '~/client/src/shared/components/NotificationTimestamp'
import BaseNotification from '~/client/src/shared/models/Notification'
import {
  MARK_NOTIFICATIONS_AS_ARCHIVED,
  VIEW_NOTIFICATION,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import {
  isDeleteType,
  isDeliveryType,
} from '~/client/src/shared/types/NotificationTypes'
import ElementSwiper from '~/client/src/shared/utils/elementSwipeHelper'

export interface IProps {
  notification: BaseNotification
  eventsStore?: MobileEventStore
  sitePermitsStore?: SitePermitsStore
  projectDateStore?: ProjectDateStore
}

@inject('eventsStore', 'sitePermitsStore', 'projectDateStore')
@observer
export default class NotificationItem extends React.Component<IProps> {
  private elementSwiper: ElementSwiper

  public render() {
    const { wasRead, isArchived } = this.props.notification

    return (
      <div>
        <div className="notification-item" ref={this.setContainerRef}>
          <div
            onClick={this.viewNotification}
            className={classList({
              'pa10 bb-light-grey full-width': true,
              'notification-item-content': true,
              'notification-read': wasRead,
            })}
          >
            <div className="col">
              {this.renderUsernameRow()}
              {this.renderNotificationInfo()}
              {this.renderThreadInfo()}
              {this.renderNotificationActions()}
            </div>
          </div>
          {!isArchived && (
            <div className="notification-item-swipe-area">
              <div
                className="col x-center y-center"
                onClick={this.archiveNotification}
              >
                <Icons.Archive />
                <div>Archive</div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  private renderUsernameRow() {
    const { notification } = this.props
    const isUnread = !notification.wasRead

    return (
      <div className="row y-start x-start mb5">
        <div className="col no-grow" style={{ flex: '1 1 38px' }} />
        <div className="col">
          <Text bold={isUnread} className="large">
            <NotificationAuthor
              notification={notification}
              showCompany={true}
            />
          </Text>
        </div>
        <div className="col no-grow" style={{ flex: '1 1 60px' }}>
          <NotificationTimestampComponent time={notification.createdAt} />
        </div>
      </div>
    )
  }

  private renderNotificationInfo() {
    const { notification } = this.props

    return (
      <>
        <NotificationLabel
          notification={notification}
          hideActivityName={true}
        />
        <div className="row y-start x-start mb5">
          <HGap size="38" />
          <NotificationEntityName notification={notification} />
        </div>
      </>
    )
  }

  private renderThreadInfo() {
    const { notification } = this.props

    return (
      <>
        <div className="row y-start x-start mb5">
          <HGap size="38" />
          <NotificationMessage notification={notification} />
        </div>
        <div className="row y-start x-start mb5">
          <HGap size="38" />
          <NotificationDocuments notification={notification} />
        </div>
      </>
    )
  }

  private renderNotificationActions() {
    const { type } = this.props.notification
    if (isDeliveryType(type) && !isDeleteType(type)) {
      return this.renderDeliveryActions()
    }
  }

  private renderDeliveryActions() {
    return (
      <div className="row y-start x-start mb5">
        <HGap size="38" />
        <MobileDeliveryNotificationActions
          notification={this.props.notification}
        />
      </div>
    )
  }

  @action.bound
  private viewNotification() {
    const { notification, eventsStore } = this.props
    eventsStore.dispatch(VIEW_NOTIFICATION, notification)
  }

  @action.bound
  private setContainerRef(ref) {
    if (this.elementSwiper) {
      this.elementSwiper.disconnectElement()
    }

    if (ref) {
      this.elementSwiper = new ElementSwiper(ref)
    }
  }

  @action.bound
  private archiveNotification() {
    this.props.eventsStore.dispatch(MARK_NOTIFICATIONS_AS_ARCHIVED, [
      this.props.notification,
    ])
  }
}

function Text({ children, bold = false, className = '' }) {
  return (
    <div
      style={{ maxWidth: 'max-content' }}
      className={classList({
        text: true,
        [className]: true,
        bold,
      })}
    >
      {children}
    </div>
  )
}
