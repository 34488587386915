import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'

import Chat from '~/client/src/shared/components/Chat/Chat'

import BottomNavigationNavBar from '../../components/BottomNavigationNavBar/BottomNavigationNavBar'
import MobileView from '../../components/MobileView'
import MobileInitialState from '../../stores/MobileInitialState'
import MobileCommonStore from '../../stores/ui/MobileCommon.store'

interface IProps {
  state?: MobileInitialState
  common?: MobileCommonStore
}

@inject('state', 'common')
@observer
class ChatView extends React.Component<IProps & RouteComponentProps> {
  public render() {
    const { state, common } = this.props
    const { hideBottomNavBar, showBottomNavBar } = common

    return (
      <MobileView
        content={
          <Chat
            onChatLogMount={hideBottomNavBar}
            onChatLogUnmount={showBottomNavBar}
            onChatCreationMenuMount={hideBottomNavBar}
            onChatCreationMenuUnmount={showBottomNavBar}
            recipientIdForInit={state.recipientIdForChatInit}
          />
        }
        footer={<BottomNavigationNavBar />}
      />
    )
  }
}

export default withRouter(ChatView)
